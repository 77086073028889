import {FollowingLog as FollowingLogContract} from "@graphql/api";
import { useMemo } from "react";
import FollowingLog from "./FollowingLog"
import './FollowingLogGroup.css'

export default function FollowingLogGroup({logs}: { logs: Array<FollowingLogContract> }) {
  const otherLogs = useMemo(() => {
    return logs.slice(0, logs.slice(1).findIndex(log => log.action === 'landed'))
  }, [logs])

  return (
    <div className="Log-group-other space-y-1">
      {logs.map(log => <FollowingLog key={log.id} log={log}/>)}
    </div>
  )
}
