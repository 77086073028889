import {prodConfig} from '@amplify-exports';
import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api-graphql';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {Amplify} from 'aws-amplify';
import {ReactNotifications} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import {Navigate, Route, Routes} from 'react-router-dom';
import Admin from './Admin/Admin';
import Create from './Admin/Broadcast/Create';
import BroadcastList from './Admin/Broadcast/List';
import PhotosList from './Admin/Photos/List';
import SafetyCommsCreate from './Admin/SafetyComms/Create';
import SafetyCommsList from './Admin/SafetyComms/List';
import UserList from './Admin/UserList/UserList';
import Login from './Authentication/Login';
import Dashboard from './Dashboard';
import Following from './Following/Following';
import FollowingLogAircraft from './Following/FollowingLogAircraft';
import FollowingLogs from './Following/FollowingLogs';

Amplify.configure({
  ...prodConfig,
  aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
})

const App = () => {
  return (
    <Authenticator.Provider>
      <ReactNotifications/>
      <Login>
        <Routes>
          <Route path="following" element={<Following/>}>
            <Route path=":aircraft" element={<FollowingLogAircraft/>}/>
            <Route index element={<FollowingLogs/>}/>
          </Route>
          <Route path="admin" element={
            <div className="flex-grow flex-row justify-center w-full mx-auto lg:px-10 pt-8">
              <Admin/>
            </div>
          }>
            <Route path="broadcasts">
              <Route path="create" element={<Create/>}/>
              <Route index element={<BroadcastList/>}/>
            </Route>
            <Route path="safety-comms">
              <Route path="create" element={<SafetyCommsCreate/>}/>
              <Route index element={<SafetyCommsList />}/>
            </Route>
            <Route path="users" element={<UserList/>}/>
            <Route path="photos" element={<PhotosList/>}/>
            <Route index element={<Navigate to="/admin/broadcasts" replace/>}/>
          </Route>
          <Route path="/" element={
            <div className="flex-grow flex-row justify-center w-full mx-auto lg:px-10 pt-8">
              <Dashboard/>
            </div>
          }/>
        </Routes>
      </Login>
    </Authenticator.Provider>
  )
}

export default App
