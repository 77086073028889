import {faVolumeMute} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {FollowingLog} from "@graphql/api";
import {listFollowingLogsByCreatedAt} from "@graphql/queries"
import {onCreateFollowingLog} from "@graphql/subscriptions"
import {API, graphqlOperation} from "aws-amplify"
import {groupBy, mapValues, sortBy} from "lodash-es"
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useState } from 'react';
import {Outlet, useLocation} from "react-router-dom";
import '../App.css';
import Map from "./Map"

const Following = () => {
  const location = useLocation();
  const [logs, setLogs] = useState<Array<any>>([])
  const [rawLogs, setRawLogs] = useState<Array<FollowingLog>>([])
  const [muted, setMuted] = useState<boolean>(true)
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const [grouped, setGrouped] = useState<Record<string, Array<FollowingLog>>>({})

  const loadLocations = async () => {
    const data = await API.graphql(graphqlOperation(listFollowingLogsByCreatedAt, {
      modelType: 'following',
      sortDirection: 'DESC',
      limit: 500,
    }))

    if (data && (data as any).data) {
      const logs = (data as any).data.listFollowingLogsByCreatedAt.items
      setRawLogs(logs)
    }
  }

  const triggerSound = async (sound: 'lifted' | 'landed' | 'enroute' | 'emergency') => {
    let name = {
      lifted: 'start',
      landed: 'stop',
      enroute: 'report',
      emergency: 'alarm',
    }[sound]
    let aud = new Audio(`/assets/${name}.mp3`)
    await aud.play()
  }

  useEffect(() => {
    const aircraft: any = {}
    const grouped: Record<string, Array<FollowingLog>> = mapValues(
      groupBy(rawLogs, 'aircraft'),
      (items, key) => sortBy(items, '-createdAt')
    )
    setGrouped(() => grouped)

    for (let log of rawLogs) {
      if (log.lat && log.lng) {
        if (
          !aircraft.hasOwnProperty(log.aircraft)
          || aircraft[log.aircraft!].createdAt < log.createdAt!
        ) {
          aircraft[log.aircraft!] = log
        }
      }
    }

    setLogs(() => Object.values(aircraft))
  }, [rawLogs])

  useEffect(() => {
    loadLocations()
  }, [])

  useEffect(() => {
    if (subscribed) {
      return
    }

    setSubscribed(true)

    let subscription = API.graphql(graphqlOperation(onCreateFollowingLog))
      // @ts-ignore
      .subscribe({
        next: async (payload: any) => {
          const newLog = payload.value.data.onCreateFollowingLog
          setRawLogs((logs) => [newLog, ...logs])
          await triggerSound(newLog.action)
        },
        error: (payload: any) => {
          console.error('Error onCreateFollowingLog subscription', {error: payload.error})
        }
      })

    // @ts-ignore
    return () => {
      setSubscribed(false)
      subscription.unsubscribe()
    }
  }, [])

  return (
    <div className="App">
      <aside className="App-sidebar bg-white">
        {location.pathname === '/following' && muted && <button className="button" onClick={async () => {
          setMuted(false);
          triggerSound('lifted')
        }}>
          <FontAwesomeIcon fixedWidth icon={faVolumeMute} color={'#fc4545'}/> Unmute
        </button>
        }

        <div className="App-logs divide-y divide-y-2">
          <Outlet/>
        </div>
      </aside>
      <main className="App-main">
        <Map logs={logs} grouped={grouped}/>
      </main>
    </div>
  );
}

export default Following;
