import {faAlarmExclamation, faBatteryEmpty, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatterySlash, faBatteryThreeQuarters, faClouds, faCompass, faRuler, faTachometer} from "@fortawesome/pro-regular-svg-icons"
import {faArrowRight, faGasPump, faLocationSlash, faPlane, faPlaneArrival, faPlaneDeparture} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import getDistance from "geolib/es/getDistance"
import {DateTime} from "luxon"
import { useMemo } from "react";
import './FollowingLog.css'

export default function FollowingLog({log}: { log: any }) {
  const logClass = useMemo(() => {
    return `Log-${log.action}`
  }, [log])

  const logIcon = useMemo(() => {
    let icon, color
    switch (log.action) {
      case 'emergency':
        icon = faAlarmExclamation;
        color = '#8c5e5e'
        break
      case 'lifted':
        icon = faPlaneDeparture;
        color = '#889699'
        break;
      case 'landed':
        icon = faPlaneArrival;
        color = '#5a7351'
        break;
      case 'techstop':
        icon = faGasPump;
        color = '#5a7351'
        break
      default:
      case 'enroute':
        icon = faPlane;
        color = '#688a8f';
        break;
    }

    return {
      icon,
      color,
    }
  }, [log])

  const noCoords = useMemo(() => {
    return !log.lat || !log.lng
  }, [log])

  const distanceToDest = useMemo(() => {
    if (!(log.lat && log.lng && log.d_lat && log.d_lng)) {
      return null
    }

    return (getDistance({lat: log.lat, lng: log.lng}, {lat: log.d_lat, lng: log.d_lng}) * 0.0005399568).toFixed(1)
  }, [log])

  const batteryIcon = useMemo(() => {
    if (!log.battery || log.battery < 0) {
      return faBatterySlash
    }

    const battery = Math.floor(log.battery * 100)

    if (battery >= 80) {
      return faBatteryFull
    } else if (battery >= 60) {
      return faBatteryThreeQuarters
    } else if (battery >= 40) {
      return faBatteryHalf
    } else if (battery >= 20) {
      return faBatteryQuarter
    } else {
      return faBatteryEmpty
    }
  }, [log])

  const gs = useMemo(() => {
    return log?.gs ? `${(log.gs * 1.944).toFixed(0)} kts` : ''
  }, [log?.gs])

  const hdg = useMemo(() => {
    return log?.hdg ? log.hdg?.toFixed(0) : ''
  }, [log?.hdg])

  const altitude = useMemo(() => {
    return log.altitude ? `${(log.altitude * 3.281).toFixed(0)}ft` : ''
  }, [log?.altitude])

  return (
    <div className={`relative ${logClass} ${noCoords ? 'Log-col-nocoords' : ''} flex flex-row`}>
      <div className="Log-col-aircraft">
        <small className={'uppercase'}>{log.type}</small>
        <small>{log.action}</small>
        <small>{log.pob} pob</small>
      </div>

      <div className={`Log-col-details`}>
        <div className={'title-line flex flex-row justify-between items-center'}>
          <p>
            {DateTime.fromISO(log.createdAt).toRelative()} <small>({DateTime.fromISO(log.createdAt).toFormat('HH:mm:ss')})</small>
          </p>
          <small className={'battery'}><FontAwesomeIcon fixedWidth icon={batteryIcon}/> {Math.floor(log.battery * 100)}%</small>
        </div>
        <div className={`user-line`}>
          <small className={'username'}>{log.pilotName || log.user}</small>
        </div>
        <p style={{fontSize: '0.75em'}}>{log.origin} <FontAwesomeIcon fixedWidth icon={faArrowRight} color={'#fc4545'}/> {log.destination}</p>
        <p className={'flex flex-row justify-between w-full whitespace-nowrap'}>
          {distanceToDest !== null && (<span className={'flex flex-1 items-center'}><FontAwesomeIcon fixedWidth icon={faRuler} className={'mr-1'}/>{distanceToDest}nm</span>)}
          <span className={'flex flex-1 items-center'}><FontAwesomeIcon fixedWidth icon={faTachometer} className={'mr-1'}/>{gs}</span>
          <span className={'flex flex-1 items-center'}><FontAwesomeIcon fixedWidth icon={faCompass} className={'mr-1'}/>{hdg}</span>
          {altitude && <span className={'flex flex-0 items-center'}><FontAwesomeIcon fixedWidth icon={faClouds} className="mr-1"/>{altitude}</span>}
        </p>

        {noCoords && (
          <p className={'text-red-500 font-bold'}>
            <FontAwesomeIcon fixedWidth icon={faLocationSlash} color={'#fc4545'} className={'mr-1'}/>
            No coordinates available
          </p>
        )}
      </div>

      <div className="Log-col-icon">
        <FontAwesomeIcon fixedWidth icon={logIcon.icon} color={logIcon.color} size={'4x'}/>
      </div>
    </div>
  )
}
