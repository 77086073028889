import { memo, useState } from 'react';
import * as React from 'react';

type Props = {
  className?: string
  children: React.ReactNode
  tooltip: React.ReactNode | string
}

const TooltipButton = ({className, children, tooltip, ...others}: Props & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  const [tooltipVisible, showTooltip] = useState<boolean>(false)

  return (
    <button className={`btn relative ${className}`} {...others} onMouseOver={() => showTooltip(true)} onMouseOut={() => showTooltip(false)}>
      {children}
      <div className={[(tooltipVisible ? undefined : 'hidden'), 'absolute bottom-0 right-0 p-1 z-10 w-auto mb-1 leading-tight transform translate-y-full bg-white rounded ring ring-gray-200'].join(' ')}>
        {tooltip}
      </div>
    </button>
  )
}

export default memo(TooltipButton)
