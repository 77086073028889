import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api-graphql';
import {AmplifyS3Image} from '@aws-amplify/ui-react-v1';
import {faPhotoVideo} from '@fortawesome/pro-duotone-svg-icons';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FlightPassenger, PhotoAsset} from '@graphql/api';
import {listPhotoAssetsWithFlight} from '@graphql/custom-queries';
import {API} from 'aws-amplify';
import {orderBy} from 'lodash-es';
import {DateTime} from 'luxon';
import {Fragment, useEffect, useState} from 'react';

const List = () => {
  const [photos, setPhotos] = useState<Array<PhotoAsset>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const loadPhotos = async () => {
    setLoading(true)
    let nextToken: string | null = null;
    let items: Array<any> = [];

    do {
      const {data} = await API.graphql({
        query: listPhotoAssetsWithFlight,
        variables: {
          nextToken,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }) as any;

      if (data?.listPhotoAssets?.items) {
        nextToken = data.listPhotoAssets.nextToken
        items = items.concat(data.listPhotoAssets.items)
      }
    }
    while (nextToken);

    items = orderBy(items, ['createdAt'], ['desc'])
    setPhotos(items)
    setLoading(false)
  }

  useEffect(() => {
    loadPhotos();
  }, []);

  const sales = (photo: PhotoAsset) => {
    return (
      <span>
        Digital: {photo.salesAtEvent?.digital?.copies}<br/>
        Physical: {photo.salesAtEvent?.physical?.copies}
      </span>
    )
  };

  return (
    <div className="w-full">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0 w-full flex flex-row items-center">
              <span className="flex-1 my-2">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Photo Sales</h3>
                <p className="mt-1 text-sm text-gray-600">View all photo sales so far</p>
              </span>
              {isLoading && <FontAwesomeIcon fixedWidth icon={faSpinner} size="lg" spin className="mr-2"/>}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Event
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Flight<br/>
                  (Departed)
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Passengers
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  Sold
                </th>
                <th scope="col" className="px-6 py-3 tracking-wider whitespace-nowrap"/>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {photos.map(photo => (
                <Fragment key={photo.id}>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        <span className="capitalize">{photo.event?.description ?? 'Unknown'}</span>
                      </div>
                      <div className="text-sm text-gray-500">{photo.createdAt ? DateTime.fromISO(photo.createdAt).toLocaleString(DateTime.DATETIME_FULL) : null}</div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm font-bold text-gray-900">{photo.flight?.designator ?? '-'}{photo.flight?.sequence ?? '-'}</div>
                      <div className="text-sm text-gray-500">{photo.flight?.actualDeparture ? DateTime.fromISO(photo.flight?.actualDeparture).toLocaleString(DateTime.TIME_24_SIMPLE) : null}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {JSON.parse(photo?.passengers ?? '[]').map((passenger: FlightPassenger) => `${passenger?.forename} ${passenger?.surname}`).join(', ')}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {sales(photo)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-0">
                      <button className="px-6 py-4 rounded transition hover:ring ring-indigo" onClick={() => photo.file}>
                        <AmplifyS3Image imgKey={photo.file?.key} className="w-5"/>
                        <FontAwesomeIcon fixedWidth size="lg" icon={faPhotoVideo}/>
                      </button>
                    </td>
                  </tr>
                </Fragment>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
