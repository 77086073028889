import {FollowingLog} from "@graphql/api";
import {listFollowingLogsByCreatedAt} from "@graphql/queries";
import {onCreateFollowingLog} from "@graphql/subscriptions";
import {API, graphqlOperation} from "aws-amplify";
import {groupBy, mapValues, sortBy} from "lodash-es";
import { useEffect, useState } from "react";
import FollowingLogSummary from "./FollowingLogSummary";

const FollowingLogs = () => {
  const [grouped, setGrouped] = useState<Record<string, Array<FollowingLog>>>({})
  const [rawLogs, setRawLogs] = useState<Array<FollowingLog>>([])
  const [subscribed, setSubscribed] = useState<boolean>(false)

  useEffect(() => {
    loadLocations()
  }, [])

  useEffect(() => {
    if (subscribed) {
      return
    }

    setSubscribed(true)

    let subscription = API.graphql(graphqlOperation(onCreateFollowingLog))
      // @ts-ignore
      .subscribe({
        next: async (payload: any) => {
          const newLog = payload.value.data.onCreateFollowingLog
          setRawLogs((logs) => [newLog, ...logs])
          await triggerSound(newLog.action)
        },
        error: (payload: any) => {
          console.error('Error onCreateFollowingLog subscription', {error: payload.error})
        }
      })

    // @ts-ignore
    return () => {
      setSubscribed(false)
      subscription.unsubscribe()
    }
  }, []);

  const triggerSound = async (sound: 'lifted' | 'landed' | 'enroute' | 'emergency') => {
    let name = {
      lifted: 'start',
      landed: 'stop',
      enroute: 'report',
      emergency: 'alarm',
    }[sound]
    let aud = new Audio(`/assets/${name}.mp3`)
    await aud.play()
  }

  const loadLocations = async () => {
    const data = await API.graphql(graphqlOperation(listFollowingLogsByCreatedAt, {
      modelType: 'following',
      sortDirection: 'DESC',
      limit: 500,
    }));

    if (data && (data as any).data) {
      const logs = (data as any).data.listFollowingLogsByCreatedAt.items
      setRawLogs(logs)
    }
  }

  useEffect(() => {
    const aircraft: any = {}
    const grouped: Record<string, Array<FollowingLog>> = mapValues(
      groupBy(rawLogs, 'aircraft'),
      (items, key) => sortBy(items, '-createdAt')
    )
    setGrouped(() => grouped)

    for (let log of rawLogs) {
      if (log.lat && log.lng) {
        if (
          !aircraft.hasOwnProperty(log.aircraft)
          || aircraft[log.aircraft!].createdAt < log.createdAt!
        ) {
          aircraft[log.aircraft!] = log
        }
      }
    }
  }, [rawLogs])

  return (
    <div className="grid grid-cols-1 divide-y space-y-1">
      {Object.entries(grouped).sort((a, b) => a[0].localeCompare(b[0])).map(([group, logs]) => (
        <FollowingLogSummary key={group} log={logs[0]}/>
      ))}
    </div>
  )
}

export default FollowingLogs;
