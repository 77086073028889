import {CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js';
import {Auth} from 'aws-amplify';
import {DateTime} from 'luxon';
import {ChangeEvent, Fragment, useCallback, useEffect, useRef, useState} from 'react';
import './Form004.css';

const nhlLogo = require('../../assets/nhl-logo.png');

class FuelPlan {
  public from?: string;
  public to?: string;
  public minfuel?: number;
  public fuelonboard?: number;
  public deptime?: string;
  public arrtime?: string;

  constructor({from, to, minfuel, fuelonboard, deptime, arrtime}: {from?: string, to?: string, minfuel?: number, fuelonboard?: number, deptime?: string, arrtime?: string}) {
    this.from = from;
    this.to = to;
    this.minfuel = minfuel;
    this.fuelonboard = fuelonboard;
    this.deptime = deptime;
    this.arrtime = arrtime;
  }
}

const Form004 = () => {
  const [fuelPlans, setFuelPlans] = useState<FuelPlan[]>([new FuelPlan({})]);
  let checklist = useRef<HTMLElement | null>(null);

  const [, setUser] = useState<CognitoUser>();
  const [attributes, setUserAttributes] = useState<Record<string, any>>({})

  const today = DateTime.now().toISODate() as string;

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      user.getUserAttributes((err: Error, attribs: Array<CognitoUserAttribute>) => setUserAttributes(attribs.reduce((obj, item) => Object.assign(obj, {[item.Name]: item.Value}), {})))
      setUser(user);
    })
  });

  const increaseFuelPlanItems = () => {
    setFuelPlans(prevState => {
      console.debug('Adding fuel plan item', new FuelPlan({...prevState[prevState.length - 1]}));
      const lastPlanItem = prevState[prevState.length - 1];
      return (Object.values({
        ...prevState,
        [prevState.length]: new FuelPlan({
          ...lastPlanItem,
          from: lastPlanItem.to,
          to: lastPlanItem.from,
          deptime: lastPlanItem.arrtime,
          arrtime: undefined
        }),
      }) as FuelPlan[]);
    });
  };

  const updateFuelPlan = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    setFuelPlans(prevState => (Object.values({
      ...prevState,
      [index]: {
        ...prevState[index],
        [event.target.name]: event.target.value
      }
    }) as FuelPlan[]));
  };

  const print = () => {
    setFuelPlans(prevState => prevState.filter(fuelState => fuelState.from && fuelState.to && fuelState.minfuel && fuelState.fuelonboard && fuelState.deptime && fuelState.arrtime));
    document.title = 'Form004';
    setTimeout(() => window.print(), 50);
  };

  const toggleChecklist = useCallback(() => {
    if (!checklist) {
      return;
    }

    checklist.current?.querySelectorAll('input').forEach(node => {
      if (node.nodeName === 'INPUT') {
        node.checked = true;
      }
    });
  }, [checklist]);

  return (
    <div className="container-fluid bg-transparent">
      <div className="m-0 p-0">
        <div className="page">
          <form>
            <div className="subpage space-y-6">
              <header className="flex flex-col space-y-2 w-full">
                <h1 className="uppercase text-xl font-bold">Form 004 Operational Flight Plan (OFP)</h1>

                <div className="flex flex-row border-black border divide-x divide-y divide-black w-full">
                  <div className="flex-1 flex-col divide-y divide-black">
                    <div className="bg-gray-200 border-r border-black">
                      <h2 className="uppercase text-center text-lg">Flight Operations Form</h2>
                      <h3 className="uppercase text-center text-2xl">Operational Flight Plan</h3>
                    </div>

                    <div className="flex flex-row w-full divide-x divide-black space-between border-r border-black">
                      <div className="flex-shrink flex-col">
                        <h4 className="bg-gray-200 uppercase p-2 text-xs whitespace-nowrap">
                          Form No
                        </h4>
                        <p className="px-2 font-bold text-sm whitespace-nowrap">OPS 004</p>
                      </div>
                      <div className="flex-shrink flex-col">
                        <h4 className="bg-gray-200 uppercase p-2 text-xs whitespace-nowrap">
                          Issue
                        </h4>
                        <p className="px-2 font-bold text-sm">3</p>
                      </div>
                      <div className="flex-shrink flex-col">
                        <h4 className="bg-gray-200 uppercase p-2 text-xs whitespace-nowrap">
                          Issue Date
                        </h4>
                        <p className="px-2 font-bold text-sm">01-01-23</p>
                      </div>
                      <div className="flex-grow w-4xl bg-gray-200">
                        <h4 className="uppercase p-2 text-xs h-full text-center">
                          <strong>Northumbria Helicopters Limited</strong> Newcastle International Airport Newcastle-upon-Tyne NE13 7BT TEL: 0191-2866999
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="flex-0 p-2 w-32 flex border-none">
                    <img src={nhlLogo} alt="NHL Logo" className="self-center" />
                  </div>
                </div>
              </header>

              <article>
                <div className="grid grid-flow-row-dense grid-cols-6 grid-rows-3 w-full border border-black">
                  <h4 className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-b border-black text-right">
                    Date of Flight
                  </h4>
                  <p className="col-span-2 border-r border-b border-black">
                    <input type="date" className="form-input w-full border-none placeholder-shown:bg-red-100" placeholder="Date of flight" defaultValue={today} required
                           autoFocus />
                  </p>

                  <h4 className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-b border-black text-right">
                    Type of Operation:
                  </h4>
                  <p className="col-span-2 border-b border-black"><input type="text" className="form-input w-full border-none placeholder-shown:bg-red-100" placeholder="Type of operation" required /></p>

                  <h4 className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-b border-black text-right">
                    Commander:
                  </h4>
                  <p className="col-span-2 border-r border-b border-black">
                    <input type="text" className="form-input w-full border-none placeholder-shown:bg-red-100" placeholder="Surname" defaultValue={attributes?.name} required />
                  </p>

                  <h4 className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-b border-black text-right">
                    Base:
                  </h4>
                  <p className="col-span-2 border-b border-black"><input type="text" className="form-input w-full border-none placeholder-shown:bg-red-100" placeholder="Base" required /></p>

                  <h4 className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">
                    Reg:
                  </h4>
                  <p className="col-span-2 border-r border-black"><input type="text" autoCapitalize="characters" className="form-input w-full border-none placeholder-shown:bg-red-100" placeholder="Aircraft reg" required /></p>

                  <h4 className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">
                    Customer:
                  </h4>
                  <p className="col-span-2"><input type="text" className="form-input w-full border-none placeholder-shown:bg-red-100" placeholder="Customer" required defaultValue="ADV001" /></p>
                </div>
              </article>

              <header className="text-center">
                <h2 className="uppercase text-xl text-center">
                  Checklist
                </h2>
                <p className="text-sm">To be carried on flight (A to B)</p>
              </header>

              <article ref={node => checklist.current = node}>

                <div className="p-2 items-end w-full print:hidden">
                  <button className="px-4 py-1 bg-slate-200 rounded shadow-md focus:ring-2 focus:ring-purple-600 focus:ring-offset-2" type="button" onClick={toggleChecklist}>Toggle all checkboxes</button>
                </div>

                <div className="grid grid-flow-row-dense grid-cols-4 w-full divide-x divide-y divide-black border-r border-black border-b">
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2 border-t border-l border-black">A/C DOCS</h4>
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2">Manuals</h4>
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2">Crew</h4>
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2 border-r border-black">Other</h4>

                  <div className="flex flex-row">
                    <label htmlFor="cofr" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">CofR & CofA</label>
                    <label className="p-2 flex-shrink">
                      <input radioGroup="acdocs" type="checkbox" id="cofr" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="opsman" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Ops Man</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="opsman" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="licensemedical" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">License/Medical</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="licensemedical" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="notams" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Notams</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="notams" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>


                  <div className="flex flex-row">
                    <label htmlFor="cofmr" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">C of MR</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="cofmr" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="flightmanual" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Flight Manual</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="flightmanual" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="photoid" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Photo ID</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="photoid" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="weather" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Weather</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="weather" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>


                  <div className="flex flex-row">
                    <label htmlFor="aoccert" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Copy of AOC Cert</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="aoccert" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="airfieldguide" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Airfield Guide</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="airfieldguide" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="crsauth" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">CRS Auth</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="crsauth" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="wandb" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">W&B</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="wandb" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>


                  <div className="flex flex-row">
                    <label htmlFor="rtlicense" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">RT License</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="rtlicense" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="relevantcharts" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Relevant Charts</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="relevantcharts" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="ftls" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">FTL's</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="ftls" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="indatecharts" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">In Date Charts</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="indatecharts" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>


                  <div className="flex flex-row">
                    <label htmlFor="noisecert" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Noise Cert</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="noisecert" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <div className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-black text-right">&nbsp;</div>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="pilotfit" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Pilot Fit</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="pilotfit" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="paxbriefcards" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Pax Brief Cards</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="paxbriefcards" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>


                  <div className="flex flex-row">
                    <label htmlFor="techlog" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Tech Log</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="techlog" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <div className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-black text-right">&nbsp;</div>
                  </div>

                  <div className="flex flex-row">
                    <div className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-black text-right">&nbsp;</div>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="rffssuitable" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">RFFS suitable</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="rffssuitable" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>


                  <div className="flex flex-row">
                    <label htmlFor="insurancecert" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Insurance Cert</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="insurancecert" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <div className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-black text-right">&nbsp;</div>
                  </div>

                  <div className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-center">OTHER</div>

                  <div className="flex flex-row">
                    <label htmlFor="landingppr" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Landing PPR</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="landingppr" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>


                  <div className="col-span-2 flex flex-row">
                    <label htmlFor="acserviceable" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">AC Serviceable &amp; Deferred Defects</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="acserviceable" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="landingsites" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Landing Sites</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="landingsites" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="reviewroute" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Review Route</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="reviewroute" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>


                  <div className="flex flex-row">
                    <label htmlFor="sufficienthours" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Sufficient Hours</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="sufficienthours" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <div className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-black text-right">&nbsp;</div>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="fuelplanning" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Fuel Planning</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="fuelplanning" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>

                  <div className="flex flex-row">
                    <label htmlFor="withinftl" className="flex-grow bg-gray-200 text-sm p-2 whitespace-nowrap border-r border-black text-right">Within FTL</label>
                    <label className="p-2 flex-shrink">
                      <input type="checkbox" id="withinftl" className="form-checkbox rounded text-green-500" />
                    </label>
                  </div>
                </div>
              </article>

              <header className="text-center">
                <h2 className="uppercase text-xl text-center">
                  Flight Planning Information
                </h2>
                <p className="text-sm">Fill in for each flight. For Pleasure Flights fill in for each shut down or crew change.</p>
              </header>

              <article>
                <div className="grid grid-flow-row-dense auto-cols-min grid-cols-6 w-full divide-x divide-y divide-black border-r border-b border-black">
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2 border-t border-l border-black">FROM</h4>
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2">TO</h4>
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2">Min Fuel</h4>
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2">Fuel on board</h4>
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2">DEP Time</h4>
                  <h4 className="flex-grow bg-gray-200 text-sm uppercase text-center py-2">ARR Time</h4>

                  {fuelPlans.map((fuelPlan, idx) => (
                    <Fragment key={idx}>
                      <input name="from" onChange={(e) => updateFuelPlan(idx, e)} value={fuelPlans[idx].from} className="form-input print:placeholder:text-transparent" type="text" placeholder="From" />
                      <input name="to" onChange={(e) => updateFuelPlan(idx, e)} value={fuelPlans[idx].to} className="form-input print:placeholder:text-transparent" type="text" placeholder="To" />
                      <input name="minfuel" onChange={(e) => updateFuelPlan(idx, e)} value={fuelPlans[idx].minfuel} className="form-input print:placeholder:text-transparent" type="number" min="0" placeholder="Min fuel" />
                      <input name="fuelonboard" onChange={(e) => updateFuelPlan(idx, e)} value={fuelPlans[idx].fuelonboard} className="form-input print:placeholder:text-transparent" type="number" min="0" placeholder="Fuel on board" />
                      <input name="deptime" onChange={(e) => updateFuelPlan(idx, e)} value={fuelPlans[idx].deptime} className="form-input print:placeholder:text-transparent" type="time" placeholder="DEP Time" />
                      <input name="arrtime" onChange={(e) => updateFuelPlan(idx, e)} value={fuelPlans[idx].arrtime} className="form-input print:placeholder:text-transparent" type="time" placeholder="ARR Time" />
                    </Fragment>
                  ))}
                </div>

                <footer className="p-2 items-end w-full print:hidden">
                  <button className="px-4 py-1 bg-slate-200 rounded shadow-md focus:ring-2 focus:ring-purple-600 focus:ring-offset-2" type="button" onClick={() => increaseFuelPlanItems()}>Add row</button>
                </footer>
              </article>

              <div className="break-inside-avoid-page space-y-6">
                <header className="text-center">
                  <h2 className="uppercase text-xl text-center">
                    Notes
                  </h2>
                </header>

                <textarea className="form-textarea w-full" rows={10}></textarea>
              </div>

              <div className="print:hidden text-center">
                <button className="px-4 py-1 bg-slate-200 rounded shadow-md focus:ring-2 focus:ring-purple-600 focus:ring-offset-2" type="button" onClick={print}>Print</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default Form004;
