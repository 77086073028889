import {useRef} from 'react';
import {QRCode as QRCodeElement} from 'react-qrcode-logo';
import {useParams} from 'react-router-dom';
import logo from '../../assets/001.png';
import useQuery from '../../Hooks/useQuery';

const QRCode = () => {
  const query = useQuery();
  const {voucher} = useParams<{voucher: string}>();
  let qrRef = useRef<QRCodeElement | null>();

  const width = query.has('width') ? Math.max(parseInt(query.get('width')!), 256) : 256;
  const data = query.get('data') ?? false;

  console.debug('Data value', {data});

  if (!voucher) {
    return (
      <div>Voucher is required</div>
    );
  }

  const qrCode = (
    <QRCodeElement
      ref={ref => qrRef.current = ref}
      id="qr-code-canvas"
      value={voucher}
      logoImage={logo}
      logoWidth={100}
      logoHeight={100}
      eyeRadius={[
        {
          inner: [5, 0, 5, 0],
          outer: [20, 0, 20, 0],
        },
        {
          inner: [0, 5, 0, 5],
          outer: [0, 20, 0, 20]
        },
        {
          inner: [0, 5, 0, 5],
          outer: [0, 20, 0, 20]
        }
      ]}
      enableCORS
      qrStyle="squares"
      bgColor="#ffffff"
      size={width}
      ecLevel="Q"
      fgColor="#6dbcdb"
    />
  );

  if (qrCode) {
    if (data) {
      const canvas: HTMLCanvasElement = document.getElementById('qr-code-canvas') as HTMLCanvasElement;
      console.debug('Canvas', canvas);
      if (canvas) {
        const dataUrl = canvas.toDataURL();
        document.write(dataUrl);
        return <p>{dataUrl}</p>;
      }
    }

    return qrCode;
  }

  return null;
};

export default QRCode;
