import {PushSubscription, UserSetting} from "@graphql/api"
import {configureStore, createAction, createReducer} from "@reduxjs/toolkit"
import {UserType} from "aws-sdk/clients/cognitoidentityserviceprovider"

export interface AdminStore {
  loaded: boolean
  groups?: Array<[string, Array<UserType>]>
  tokens?: Array<[string, Array<PushSubscription>]>
  settings?: Array<[string, UserSetting]>
}

const actionSet = createAction<any>('store/set')
const storeGroups = createAction<Map<string, Array<any>>>('store/groups')
const storeTokens = createAction<Map<string, Array<PushSubscription>>>('store/tokens')
const storeSettings = createAction<Map<string, UserSetting>>('store/settings')

const reducer = createReducer({loaded: false} as AdminStore, (builder) => {
  builder
    .addCase<any>(actionSet, (state, action) => {
      Object.assign(state, {...action?.payload})
    })
    .addCase<any>(storeGroups, (state, action) => {
      state.groups = Array.from(action.payload.entries())
    })
    .addCase<any>(storeTokens, (state, action) => {
      state.tokens = Array.from(action.payload.entries())
    })
    .addCase<any>(storeSettings, (state, action) => {
      state.settings = Array.from(action.payload.entries())
    })
    .addDefaultCase((state, action) => state)
})

let preloadedState
const persistedTodosString = localStorage.getItem('AdminStore')

if (persistedTodosString) {
  preloadedState = JSON.parse(persistedTodosString)
}

const store = configureStore({
  reducer,
  devTools: true,
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
})

export {actionSet, storeGroups, storeTokens, storeSettings}
export default store
