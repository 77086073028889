export const getFullEvent = /* GraphQL */ `
  query GetFullEvent($id: ID!) {
    getEvent(id: $id) {
      id
      oid
      venue
      venueId
      date
      endDate
      pax
      product
      ref
      description
      aircraft
      aircraftName
      aircraftType
      aircraftSeats
      aircraftId
      duration
      client
      type
      status
      pilotName
      pilotConfirmed
      users
      currentRefuelRequest {
        id
        requestedBy
        requestFrom
        delay
        flightOrderId
        adventureId
        aircraftId
        litres
        fuelSource {
          provider
          name
        }
        fuelType
        _version
      }
      currentFlight {
        id
        designator
        sequence
        status
        flightOrderId
      }
      overview {
        id
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        team {
          items {
            id
            name
            forename
            surname
            teamResourceId
            teamRole
            phone
            confirmed
            createdAt
            updatedAt
            _version
            _deleted
          }
          nextToken
        }
        teamLeaderId
        createdAt
        updatedAt
        _version
      }
      legs {
        items {
          id
          order
          venueName
          venuePostcode
          empty
          arriving
          distance
          ete
          lat
          lng
          w3w
          status
          _version
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
    }
  }
`

export const onCreateMiniFlight = /* GraphQL */ `
  subscription OnCreateMiniFlight($eventId: ID!) {
    onCreateFlightByEventId(eventId: $eventId) {
      id
      eventId
      _version
    }
  }
`

export const onUpdateMiniFlight = /* GraphQL */ `
  subscription OnUpdateMiniFlight($eventId: ID!) {
    onUpdateFlightByEventId(eventId: $eventId) {
      id
      eventId
      _version
    }
  }
`

export const onWatchMiniFlight = /* GraphQL */ `
  subscription OnWatchMiniFlight($eventId: ID!) {
    onWatchFlightByEventId(eventId: $eventId) {
      id
      eventId
      _version
    }
  }
`


export const onUpdatePleasureEvent = /* GraphQL */ `
  subscription OnUpdatePleasureEvent {
    onCreateUpdateEvent {
      id
      type
      oid
      venue
      venueId
      date
      endDate
      pax
      product
      ref
      users
      pilotConfirmed
      aircraftId
      aircraftName
      aircraftSeats
      duration
      client
      status
      createdAt
      updatedAt
      _version
    }
  }
`;

export const listEventsForAvailability = /* GraphQL */ `
  query ListEventsForAvailability(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venue
        type
        date
        pilotConfirmed
        _version
      }
      nextToken
    }
  }
`;

export const listEventsByDateWithTeam = /* GraphQL */ `
  query ListEventsByDateWithTeam(
    $modelType: TaskTypeString!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByDate(
      modelType: $modelType
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        overview {
          id
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          team {
            items {
              id
              teamResourceId
              confirmed
            }
          }
          createdAt
          updatedAt
          _version
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftName
        aircraftSeats
        duration
        client
        status
        createdAt
        updatedAt
        _version
      }
      nextToken
    }
  }
`;

export const listFlightsByEventWithPassengers = /* GraphQL */ `
  query ListFlightsByEventWithPassengers(
    $eventId: ID!
    $scheduledDeparture: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlightsByEventSequence(
      eventId: $eventId
      scheduledDeparture: $scheduledDeparture
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        flightOrderId
        flightNumber
        designator
        sequence
        theme
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        isFlightFull
        status
        duration
        users
        updatedAt
        createdAt
        _version
        _deleted
        passengers {
          items {
            id
            tupleId
            eventId
            voucherId
            flightId
            number
            forename
            surname
            weight
            seat
            phone
            createdAt
            updatedAt
            _version
            _deleted
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listPhotoAssetsWithFlight = /* GraphQL */ `
  query ListPhotoAssetsWithFlight(
    $filter: ModelPhotoAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotoAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        file {
          bucket
          region
          key
        }
        id
        enquiryId
        eventId
        soldBy
        event {
          description
          venue
          aircraftName
          aircraft
          pilotName
        }
        salesAtEvent {
          digital {
            copies
          }
          physical {
            copies
          }
        }
        salesAfterEvent {
          digital {
            copies
          }
          physical {
            copies
          }
        }
        flight {
          aircraftName
          sequence
          designator
          actualDeparture
        }
        passengers
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;


export const aggregateManifestItems = /* GraphQL */ `
  query AggregateManifestItems(
    $filter: SearchableManifestItemFilterInput
    $sort: [SearchableManifestItemSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableManifestItemAggregationInput]
  ) {
    searchManifestItems(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const searchEventsForTaskList = /* GraphQL */ `
  query SearchEventsForTaskList(
    $filter: SearchableEventFilterInput
    $sort: [SearchableEventSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventAggregationInput]
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
        }
        teamMembers {
          items {
            id
            name
            forename
            surname
            teamResourceId
            teamRole
            phone
            confirmed
            _deleted
          }
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
