import {faCaretDown, faCaretUp} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BroadcastMessage} from "@graphql/api";
import {DateTime} from "luxon";
import { Fragment, Dispatch, memo, SetStateAction, useMemo } from "react";

type Props = {
  broadcast: BroadcastMessage
  toggled: boolean
  onToggle: Dispatch<SetStateAction<string | undefined>>
};

const Row = ({broadcast, toggled, onToggle}: Props) => {
  const targeted = useMemo(() => {
    if (broadcast?.targetedGroup) {
      return `group(s): ${broadcast.targetedGroup}`
    }
    if (broadcast?.targetedEvent) {
      return `event: ${broadcast.targetedEvent}`
    }

    if (broadcast?.targetedRole) {
      return `role: ${broadcast.targetedRole}`
    }

    if (broadcast?.targetedUsers) {
      return `user: ${Array.from(new Set(broadcast.targetedUsers)).join(', ')}`
    }

    return 'no specific users or groups targeted'
  }, [broadcast]);

  const singleUser = useMemo<string>(() => {
    const users = new Set(broadcast?.users ?? []);
    if (users.size === 1) {
      return users.values().next().value;
    }

    return false;
  }, [broadcast])

  return (
    <Fragment key={broadcast.id}>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            <span className="capitalize">{broadcast.originType}</span>
            {broadcast.originType === 'manual' && (<span> ({broadcast.originId})</span>)}
          </div>
          <div className="text-sm text-gray-500">{broadcast.createdAt ? DateTime.fromISO(broadcast.createdAt).toLocaleString(DateTime.DATETIME_FULL) : null}</div>
        </td>
        <td className="px-6 py-4">
          <div className="text-sm font-bold text-gray-900">{broadcast.title || 'New broadcast'}</div>
          <div className="text-sm text-gray-900">{broadcast.message}</div>
          {singleUser && <div className="text-sm text-gray-500">{singleUser}</div>}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            Sent
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{broadcast?.users?.length}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-0">
          <button className="px-6 py-4 rounded transition hover:ring ring-indigo" onClick={() => onToggle(prevState => prevState === broadcast.id ? undefined : broadcast.id)}>
            <FontAwesomeIcon fixedWidth size="lg" icon={toggled ? faCaretUp : faCaretDown}/>
          </button>
        </td>
      </tr>

      <tr hidden={!toggled}>
        <td className="px-6 py-4" colSpan={5}>
          <div className="text-sm text-gray-500">
            Targeted: {targeted}
          </div>
          <div className="text-sm text-blue-500">
            Delivered: {broadcast.users && broadcast.users.length > 0 ? Array.from(new Set(broadcast.users)).join(', ') : 'none'}
          </div>
        </td>
      </tr>
    </Fragment>
  );
}

export default memo(Row);
