import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FollowingLog} from "@graphql/api";
import {DateTime} from "luxon";
import { useMemo } from "react";
import {Link} from "react-router-dom";
import './FollowingLog.css'

export default function FollowingLogSummary({log}: { log: FollowingLog }) {
  const noCoords = useMemo(() => {
    return !log.lat || !log.lng
  }, [log]);

  const bgColor = useMemo(() => {
    switch (log.action) {
      case 'lifted':
      case 'enroute':
        return 'bg-green-200 hover:bg-green-200/50';
      case 'emergency':
        return 'bg-red-200 hover:bg-red-200/50';
      case 'landed':
      case 'techstop':
        return 'bg-blue-200 hover:bg-blue-200/50';
      default:
        return 'bg-white hover:bg-white/50';
    }
  }, [log.action]);

  return (
    <Link to={`/following/${log.aircraft}`} className={`${bgColor} transition`}>
      <div className={`${noCoords && 'Log-col-nocoords'} py-1 px-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 items-center`}>
        <strong className="whitespace-nowrap">{log.aircraft}</strong>
        <small className="uppercase md:text-right lg:text-left">{log.type}</small>
        <small className="">{log.action}</small>
        <div className="md:text-right whitespace-nowrap">
          {(log.action === 'enroute')
            ?
            <small>ETE: {DateTime.now().plus({hours: log.ete!}).toRelative({style: 'narrow'})}</small>
            :
            <small>
              {DateTime.fromISO(log.createdAt!).toRelative({style: 'narrow'})}
            </small>
          }
          <FontAwesomeIcon fixedWidth icon={faChevronRight}/>
        </div>
      </div>
    </Link>
  );
}
