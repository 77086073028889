import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api-graphql';
import {faBellPlus, faChevronRight} from '@fortawesome/pro-duotone-svg-icons';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BroadcastMessageString, ModelSortDirection} from '@graphql/api';
import {listBroadcastMessagesByCreatedAt} from '@graphql/queries';
import {API} from 'aws-amplify';
import {orderBy} from 'lodash-es';
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import Row from './_Row';

export enum TeamMemberRole {
  captain = 'Captain',
  ops1 = 'Ops',
  rffs1 = 'RFFS',
  photos1 = 'Photos',
}

const List = () => {
  const [broadcasts, setBroadcasts] = useState<Array<any>>([]);
  const [toggledBroadcast, toggleBroadcast] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string>();

  const loadBroadcasts = async (loadMore: boolean = false) => {
    if (isLoading || (loadMore && !nextToken)) {
      return;
    }

    setLoading(true);
    let items: Array<any> = [];

    const {data} = await API.graphql({
      query: listBroadcastMessagesByCreatedAt,
      variables: {
        modelType: BroadcastMessageString.broadcast,
        sortDirection: ModelSortDirection.DESC,
        limit: 100,
        nextToken,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }) as any;

    if (data?.listBroadcastMessagesByCreatedAt?.items) {
      setNextToken(data?.listBroadcastMessagesByCreatedAt.nextToken);
      items = items.concat(data?.listBroadcastMessagesByCreatedAt.items);
    }

    setBroadcasts(prevState => orderBy([...prevState, ...items], ['createdAt'], ['desc']));
    setLoading(false);
  };

  useEffect(() => {
    loadBroadcasts();
  }, []);

  return (
    <div className="w-full">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0 w-full flex flex-row items-center">
              <span className="flex-1 my-2">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Broadcast Safety messages</h3>
                <p className="mt-1 text-sm text-gray-600">View all prior broadcasts</p>
              </span>
              {isLoading && <FontAwesomeIcon fixedWidth icon={faSpinner} size="lg" spin className="mr-2" />}

              <Link to="/admin/broadcasts/create" className="flex-0 uppercase text-xs text-blue-400 rounded border-blue-400 border p-2" style={{height: 'fit-content'}}>
                <FontAwesomeIcon fixedWidth icon={faBellPlus} className="mr-2" />
                Create Broadcast
              </Link>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sender
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Broadcast
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  Receivers
                </th>
                <th scope="col" className="px-6 py-3 tracking-wider whitespace-nowrap" />
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {broadcasts.map(broadcast => <Row key={broadcast.id} broadcast={broadcast} onToggle={toggleBroadcast} toggled={toggledBroadcast === broadcast.id} />)}
              </tbody>

              <tfoot>
              <tr>
                <th colSpan={5}>
                  <div className="flex justify-end">
                    <button className="px-6 py-4 rounded transition hover:ring ring-indigo" onClick={() => loadBroadcasts(true)}>
                      <FontAwesomeIcon fixedWidth icon={faChevronRight} /> More
                    </button>
                  </div>
                </th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
