import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { useEffect, useMemo } from 'react';
import SVG from "react-inlinesvg";
import {useLocation} from "react-router-dom";
import logo from "./assets/logo.svg";

export const AppLinks = () => {
  const {pathname, search, hash} = useLocation();
  const appLink = useMemo(() => `overflight://${pathname.replace('/app/', '')}${search}${hash}`, [pathname, search, hash]);

  useEffect(() => {
    if (appLink) {
      window.location.href = appLink;
    }
  }, [appLink])

  return (
    <div className="flex w-screen h-screen justify-center items-center">
      <div className="flex flex-col gap-2">
        <SVG src={logo} title="OverFlight logo" className="object-contain object-center fill-current text-blue-900"/>
        <h1 className="flex flex-row gap-2 text-xl font-bold justify-center">
          <span>Opening OverFlight</span>
        </h1>
        <FontAwesomeIcon icon={faSpinner} spin/>
        <p>Not working? Try clicking this button:</p>
        <div className="flex justify-center">
          <a className="uppercase text-xs text-blue-400 rounded border-blue-400 border p-2" href={appLink}>Open OverFlight</a>
        </div>
      </div>
    </div>
  );
}
