import {faDotCircle, faFlagCheckered, faHelicopter} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FollowingAction, FollowingLog} from '@graphql/api';
import * as Geolib from 'geolib';
import {DateTime} from 'luxon';
import mapboxgl from 'mapbox-gl';
import {useEffect, useState} from 'react';
import SVG from 'react-inlinesvg';
import ReactMapboxGl, {Feature, Layer, Marker, Popup} from 'react-mapbox-gl';
import {FitBounds} from 'react-mapbox-gl/lib/map';
import B206 from '../assets/206top.svg';
import R44 from '../assets/r44top.svg';
import Timer from '../Components/Timer';
import './Map.css';

const MapContainer = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiaG91c2VhbGV4YW5kZXIiLCJhIjoiY2tqZDF3a3lxODB1MjJzbGJmN21qZXNncSJ9.XSKBLpWVCtMInS-aU_gZQw'
});

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const lineLayout = {
  'line-cap': 'round'
}
const linePaint = {
  'line-color': '#f00',
  'line-dasharray': [5, 5],
}

const colors: Partial<Record<FollowingAction, string>> = {
  landed: 'text-gray-500',
  emergency: 'text-red-500',
  techstop: 'text-yellow-500',
  enroute: 'text-green-500',
  lifted: 'text-green-500',
};

const Map = ({logs, grouped}: { logs: Array<any>, grouped: Record<string, Array<any>> }) => {
  const [lng, setLng] = useState<number>(-3.4359)
  const [lat, setLat] = useState<number>(55.3780)
  const [zoom, setZoom] = useState<number>(5)
  const [selectedLog, selectLog] = useState<any>(null)
  const [aircraft, setAircraft] = useState<Array<string>>([]);
  const [fitBounds, setFitBounds] = useState<FitBounds>([[-3.4359, 55.3780], [-3.4359, 55.3780]]);

  useEffect(() => {
    setAircraft(() => Object.keys(grouped))
  }, [grouped])

  useEffect(() => {
    if (logs?.length > 1) {
      const maxBounds = Geolib.getBounds(logs.map(log => ({latitude: log.lat, longitude: log.lng})));
      const bounds = [{latitude: maxBounds.minLat, longitude: maxBounds.minLng}, {latitude: maxBounds.maxLat, longitude: maxBounds.maxLng}];
      // const fitBounds = bounds.map(bound => [bound.longitude, bound.latitude]) as FitBounds;
      const fitBounds = [
        [Math.floor(bounds[0].longitude), Math.floor(bounds[0].latitude)],
        [Math.ceil(bounds[1].longitude), Math.ceil(bounds[1].latitude)],
      ] as FitBounds;
      console.debug(`Bounds`, {maxBounds, bounds, fitBounds});
      const center = Geolib.getCenterOfBounds(bounds);
      console.debug('Center', center);
      if (center) {
        const {latitude, longitude} = center;
        setLat(latitude);
        setLng(longitude);
      }

      setFitBounds(fitBounds);
    }
  }, [logs])

  // Initialize map when component mounts
  const onMove = async (map: mapboxgl.Map) => {
    setLng(parseFloat(map.getCenter().lng.toFixed(4)));
    setLat(parseFloat(map.getCenter().lat.toFixed(4)));
    setZoom(parseFloat(map.getZoom().toFixed(2)));
  }

  const clickMarker = async (location: any) => {
    selectLog(location)
  }

  const aircraftLogs = (aircraft: string) => {
    if (grouped[aircraft]) {
      return grouped[aircraft].slice(0, grouped[aircraft].slice(1).findIndex(log => log.action === 'landed'))
    }

    return []
  }

  const coordinates = (aircraft: string) => {
    const logs = aircraftLogs(aircraft)
    let coords = []
    if (logs.length > 1) {
      for (let i = 1; i < logs.length; ++i) {
        coords.push([[logs[i - 1].lng, logs[i - 1].lat], [logs[i].lng, logs[i].lat]])
      }
    }

    return coords
  }

  const mapIcon = (location: FollowingLog) => {
    const color = colors[location.action!] ?? 'text-blue-500';

    if (['B206', 'B206L', 'AB206'].includes(location.aircraftType!)) {
      return <SVG src={B206} title={`B206 ${location.aircraft}`} width={24} height={72} transform={`rotate(${location.hdg?.toFixed(0) ?? 0})`} className={`object-contain object-center fill-current ${color}`}/>
    }

    if (['R44'].includes(location.aircraftType!)) {
      return <SVG src={R44} title={`R44 ${location.aircraft}`} width={24} height={72} transform={`rotate(${location.hdg?.toFixed(0) ?? 0})`} className={`object-contain object-center fill-current ${color}`}/>
    }

    return <FontAwesomeIcon fixedWidth icon={faHelicopter} color={'#fc4545'} size={'2x'} title={location.aircraft!}/>
  }

  return (
    <div>
      {process.env.NODE_ENV === 'development' &&
        <div className="sidebarStyle">
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </div>
        </div>
      }

      <div className="legend">
        <div className="text-left">
          {Object.entries(colors).map(([action, color]) => (
            <p key={action} className={color}>
              <FontAwesomeIcon fixedWidth icon={faDotCircle} className={color}/>
              {action}
            </p>
          ))}
        </div>
      </div>

      <MapContainer
        style={`mapbox://styles/mapbox/outdoors-v11`}
        containerStyle={{
          height: '100vh',
          width: '75vw'
        }}
        fitBounds={fitBounds}
        fitBoundsOptions={{maxZoom: 10}}
        onMoveEnd={onMove}
      >
        {logs && logs.map((location) => (
          <div key={location.id}>
            <Marker
              coordinates={[parseFloat(location.lng), parseFloat(location.lat)]}
              onClick={() => clickMarker(location)}
              offset={[0, 36]}
            >
              <div className={`flex flex-row text-black text-bold items-center relative`}>
                {mapIcon(location)}
                <p className="absolute -right-16 top-0 ml-1 bg-white rounded-lg p-1">{location.aircraft}<br/>{location.pilotName}</p>
              </div>
            </Marker>
            <Marker coordinates={[location.d_lng, location.d_lat]}>
              <FontAwesomeIcon fixedWidth icon={faFlagCheckered} color={'#000000'} size={'2x'} title={location.destination}/>
            </Marker>
            <Layer key={`${location.aircraft}-route`} type="line" layout={lineLayout} paint={linePaint}>
              <Feature coordinates={[[parseFloat(location.lng), parseFloat(location.lat)], [parseFloat(location.d_lng), parseFloat(location.d_lat)]]}/>
            </Layer>
          </div>
        ))}

        {selectedLog && (
          // @ts-ignore
          <Popup key={selectedLog.id} coordinates={[selectedLog.lng, selectedLog.lat]} onClick={() => selectLog(null)}>
            <div className={`flex flex-col text-black text-bold items-center bg-white rounded p-1`}>
              {mapIcon(selectedLog)}
              <p>{selectedLog.aircraft}</p>
              <p>
                <Timer time={DateTime.fromISO(selectedLog.createdAt)}/>
                {selectedLog.eta && (
                  <><span> (ETA: {DateTime.fromSeconds(selectedLog.eta).toRelative()})</span></>
                )}
              </p>
              <p>{selectedLog.pilotName}</p>
              <p>{selectedLog.type} {selectedLog.action}</p>
            </div>
          </Popup>
        )}

        {aircraft.map((callsign: string) =>
          (<Layer key={`${callsign}`} type="line" paint={{'line-color': '#333', 'line-width': 2}}>
            {coordinates(callsign).map((coords, index) => (
                <Feature key={`${callsign}-${index}`} coordinates={coords}/>
              )
            )}
          </Layer>)
        )}
      </MapContainer>
    </div>
  );
};

export default Map
