import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react"
import SVG from 'react-inlinesvg';
import appstore from "../assets/appstore.svg"
import logo from "../assets/logo.svg"
import Header from "./Header"
import './Login.css'

const Login = ({children}: { children: any }) => {
  const {route} = useAuthenticator(context => [context.route]);

  if (route === 'authenticated') {
    return (
      <div className="flex flex-col space-between min-h-screen w-full">
        <Header/>
        {children}
      </div>
    )
  }

  const components = {
    Header() {
      return (
        <>
          <div className="content-center p-8 w-full">
            <SVG src={logo} title="OverFlight" className="object-contain object-center fill-current text-blue-900"/>
          </div>
          <div className="flex flex-col md:flex-row justify-center w-full">
            <a
              href="https://play.google.com/store/apps/details?id=com.ahitltd.pilot&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              className="flex h-20 w-40"
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                className="object-contain object-center"
              />
            </a>
            <a
              href="https://apps.apple.com/gb/app/overflight/id1530715816"
              className="flex h-20 w-40 items-center justify-end"
            >
              <SVG
                src={appstore}
                title="Download on the Apple App Store"
                className="object-contain object-center"
              />
            </a>
          </div>
        </>
      )
    }
  }

  return (
    <div className="flex-1 flex-col justify-center h-full w-full">
      <div className="flex flex-col justify-center items-center content-center w-full min-h-screen bg-blue-200 ">
        <Authenticator hideSignUp={true} components={components}>
          {({signOut}) => <button onClick={signOut}>Sign out</button>}
        </Authenticator>
      </div>
    </div>
  )
}

export default Login
