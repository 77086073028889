import {SignedIn, SignedOut, SignInButton, SignOutButton, useAuth, useUser} from '@clerk/clerk-react';
import {faDropbox} from '@fortawesome/free-brands-svg-icons';
import {faFileSearch, faSignIn} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {API, Auth} from 'aws-amplify';
import {useCallback, useEffect, useState} from 'react';
import {Dropbox} from './types';

type Props = {
  selectFile: (file: Dropbox.File | null) => void;
  selectedFile: Dropbox.File | null;
  dropboxToken: string | null;
  setDropboxToken: (token: string | null) => void;
}

const DropboxAccess = ({selectedFile, selectFile, dropboxToken, setDropboxToken}: Props) => {
  const {signOut} = useAuth();
  const {user} = useUser();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSearching, setSearching] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [files, setFiles] = useState<Array<any>>([]);

  const fetchDropboxToken = useCallback(async () => {
    if (isLoading) {
      return;
    }

    if (dropboxToken) {
      return dropboxToken;
    }

    setLoading(true);

    try {
      console.debug('About to fetch dropbox token');
      const {token} = await API.post('pilot001Rest', '/dropbox/token', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          // Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken(),
        },
        body: {
          userId: user?.id,
        },
      });

      const dropboxToken = token.at(0).token;
      setDropboxToken(dropboxToken);
    } catch (err) {
      console.error('Error fetching dropbox token');
      await signOut();
    } finally {
      setLoading(false);
    }
  }, [dropboxToken, isLoading, setDropboxToken, signOut, user?.id]);

  const searchFiles = async () => {
    if (isSearching || !searchQuery) {
      return;
    }
    setSearching(true);
    selectFile(null);
    setFiles([]);

    await fetchDropboxToken();
    try {
      const files = await fetch('https://api.dropboxapi.com/2/files/search_v2', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${dropboxToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: searchQuery,
          options: {
            filename_only: true,
            file_categories: ['pdf'],
          }
        })
      });

      const json = await files.json();
      setFiles(json.matches.filter((file: any) => file.metadata.metadata['.tag'] !== 'folder'));
    } catch (err) {
      setDropboxToken(null);
      await fetchDropboxToken();
    } finally {
      setSearching(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchDropboxToken();
    }
  }, [fetchDropboxToken, user]);

  return (
    <div className="col-span-3">
      <SignedIn>
        <p>Select a file to use for this SafetyCom</p>

        <form onSubmit={searchFiles}>
        <input
          type="search"
          placeholder="Search files"
          className="input mr-3"
          onChange={e => setSearchQuery(e.target.value)}
          onEmptied={e => setFiles([])}
          spellCheck={false}
          autoCorrect={'off'}
        />
        <button
          onClick={searchFiles}
          className="form-input flex-0 uppercase text-blue-400 rounded border-blue-400 border p-2"
          style={{height: 'fit-content'}}
          disabled={isSearching}
        >
          <FontAwesomeIcon fixedWidth icon={faFileSearch} className="mr-2" />
          Search for file
        </button>
        </form>

        {isSearching && <p>Searching...</p>}

        {files.length > 0 && !selectedFile && (
          <table className="flex-1 min-w-full border-collapse">
            <thead>
            <tr>
              <th>File</th>
              <th>Path</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {files.map((file: Dropbox.File) => (
              <tr key={file.metadata.metadata.id} className="border-y border-slate-300">
                <td>{file.metadata.metadata.name}</td>
                <td><code>{file.metadata.metadata.path_display}</code></td>
                <td>
                  <button className="flex-0 uppercase text-xs text-blue-400 p-2" onClick={() => selectFile(file)}>Select this file</button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}

        <div className="hidden">
        <SignOutButton>
          <button className="flex-0 uppercase text-xs text-blue-400 rounded border-blue-400 border p-2">
            <FontAwesomeIcon fixedWidth icon={faSignIn} className="mr-2" />
            Sign Out
          </button>
        </SignOutButton>
        </div>
      </SignedIn>
      <SignedOut>
        <p>Sign in to select a file from Dropbox</p>
        <SignInButton>
          <button className="flex-0 uppercase text-xs text-blue-400 rounded border-blue-400 border p-2">
            <FontAwesomeIcon fixedWidth icon={faDropbox} className="mr-2" />
            Sign In
          </button>
        </SignInButton>
      </SignedOut>
    </div>
  );
};

export default DropboxAccess;
