import {GRAPHQL_AUTH_MODE, GraphQLResult} from '@aws-amplify/api-graphql';
import {faUserPilotTie, IconDefinition} from '@fortawesome/pro-duotone-svg-icons';
import {faEye, faHardHat, faSpinner, faUsers, faWrench} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ListPushSubscriptionsByChannelQuery, ListUserSettingsQuery, PushChannel, PushSubscription, UserSetting} from '@graphql/api';
import {listPushSubscriptionsByChannel, listUserSettings} from '@graphql/queries';
import {API} from 'aws-amplify';
import {UserType} from 'aws-sdk/clients/cognitoidentityserviceprovider';
import {groupBy, keyBy} from 'lodash-es';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select, {components} from 'react-select';
import {actionSet, AdminStore, storeGroups, storeSettings, storeTokens} from '../../Store';
import AdminQueries from './AdminQueries';
import User, {UserGroup} from './User';

export const GroupIcons = new Map<UserGroup, IconDefinition>([
  ['Admin', faEye],
  ['Pilot', faUserPilotTie],
  ['Ground', faHardHat],
  ['Maintenance', faWrench],
  ['TeamLead', faUsers],
])

// @ts-ignore
const SelectMultiValueLabel = (props) => (
  <components.MultiValueLabel {...props}>
    <div className="whitespace-no-wrap"><FontAwesomeIcon fixedWidth title={props.data.label} icon={GroupIcons.get(props.data.label as UserGroup) as IconDefinition} /></div>
  </components.MultiValueLabel>
)
// @ts-ignore
const SelectOption = (props) => (
  <components.Option {...props}>
    <div className="whitespace-no-wrap"><FontAwesomeIcon fixedWidth title={props.label} icon={GroupIcons.get(props.label as UserGroup) as IconDefinition} /> {props.children}</div>
  </components.Option>
)

const UserList = () => {
  const {loaded: loadedData, settings, tokens, groups} = useSelector((state: AdminStore) => {
    return {
      loaded: state.loaded,
      settings: state.settings ? new Map<string, UserSetting>(state.settings) : undefined,
      tokens: state.tokens ? new Map<string, Array<PushSubscription>>(state.tokens) : undefined,
      groups: state.groups ? new Map<string, Array<UserType>>(state.groups) : undefined,
    }
  })
  const [users, setUsers] = useState<Array<UserType>>([])
  const [userGroups, setUserGroups] = useState<Map<string, Set<UserGroup>>>(new Map())
  const [userToken, setUserToken] = useState<string>()
  const [loadedTokens, setLoadedTokens] = useState<Set<string | undefined>>(new Set())
  const [filters, setFilters] = useState<Record<string, any>>({})
  const [page, setPage] = useState<number>(0)
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  let userDiv = useRef<HTMLTableDataCellElement>(null)

  const dispatch = useDispatch()

  const fetchUserSettings = useCallback(async (): Promise<Map<string, UserSetting>> => {
    let settings: Record<string, UserSetting> = {}
    let nextToken = null
    do {
      const {data} = await API.graphql({
        query: listUserSettings,
        variables: {
          nextToken,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }) as GraphQLResult<ListUserSettingsQuery>
      nextToken = data?.listUserSettings?.nextToken
      Object.assign(settings, keyBy(data?.listUserSettings?.items as Array<UserSetting>, 'owner'))
    } while (nextToken !== null)

    dispatch(storeSettings(new Map<string, UserSetting>(Object.entries(settings))))

    return new Map<string, UserSetting>(Object.entries(settings))
  }, [dispatch])

  const fetchUserBroadcastTokens = useCallback(async (): Promise<Map<string, Array<PushSubscription>>> => {
    let allTokens: Array<PushSubscription> = []
    let nextToken = null
    do {
      const {data} = await API.graphql({
        query: listPushSubscriptionsByChannel,
        variables: {
          channel: PushChannel.BROADCAST,
          nextToken,
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }) as GraphQLResult<ListPushSubscriptionsByChannelQuery>
      nextToken = data?.listPushSubscriptionsByChannel?.nextToken
      allTokens = allTokens.concat(data?.listPushSubscriptionsByChannel?.items as Array<PushSubscription> ?? [])
    } while (nextToken !== null)

    dispatch(storeTokens(new Map<string, Array<PushSubscription>>(Object.entries(groupBy(allTokens, 'user')))))
    return new Map(Object.entries(groupBy(allTokens, 'user')))
  }, [dispatch])

  const loadGroups = useCallback(async () => {
    const {groups} = await AdminQueries.getInstance().listGroups()
    const usersInGroups: Map<string, Array<any>> = new Map()

    for (const group of groups) {
      if (usersInGroups.has(group.GroupName)) {
        continue
      }

      let nextToken: string | undefined | null = null;
      let groupUsers: Array<any> = []
      do {
        const {users, token}: any = await AdminQueries.getInstance().listUsersInGroup(group.GroupName, nextToken);
        nextToken = token;
        groupUsers = groupUsers.concat(users)
      } while (nextToken !== undefined && nextToken !== null)

      usersInGroups.set(group.GroupName, groupUsers)
    }

    dispatch(storeGroups(usersInGroups))
    return usersInGroups
  }, [dispatch])

  const fetchUsers = useCallback(async () => {
    // don't fetch if we've already fetched this page
    if (loadedTokens.has(userToken)) {
      return
    }
    setLoadedTokens(prevState => prevState.add(userToken))
    setLoadingUsers(() => true)

    try {
      setPage(page => page + 1)
      const {users, token} = await AdminQueries.getInstance().listUsers(userToken, 'status="Enabled"')
      setUsers(prevState => [...prevState, ...users as unknown as Array<UserType>])
      setUserToken(() => token)
    } catch (err) {
      setLoadedTokens(prevState => {
        prevState.delete(userToken)
        return prevState
      })
      throw err
    } finally {
      setLoadingUsers(false)
    }
  }, [loadedTokens, userToken])

  const handleObserver = useCallback((entities: Array<IntersectionObserverEntry>) => {
    const target = entities[0]
    if (target.isIntersecting && !loadedTokens.has(userToken)) {
      fetchUsers()
    }
  }, [fetchUsers, userToken, loadedTokens])

  useEffect(() => {
    if (loadedData) {
      return
    }
    dispatch({type: actionSet, payload: {loaded: true}})

    fetchUserSettings()
    fetchUserBroadcastTokens()
    loadGroups()
  }, [loadedData, dispatch, fetchUserSettings, fetchUserBroadcastTokens, loadGroups])

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {root: null, rootMargin: '100px 0px 0px 0px', threshold: 1.0})
    if (userDiv.current) {
      observer.observe(userDiv.current)
    }
  }, [handleObserver])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers, page])

  useEffect(() => {
    if (users && groups) {
      groups.forEach((groupUsers, group) => {
        groupUsers.forEach(groupUser => {
          const existingGroups = userGroups.get(groupUser.Username as string) || new Set()
          existingGroups.add(group as UserGroup)
          setUserGroups(prevState => prevState.set(groupUser.Username as string, existingGroups))
        })
      })
    }
  }, [users, groups, userGroups])

  const filteredUsers = useMemo<Array<UserType>>(() => {
    let filtered: Array<UserType> = [...users]

    for (let filter of Object.keys(filters)) {
      const regex = new RegExp(filters[filter], 'i')
      filtered = filtered.filter(user => {
        if (filter === 'Username') {
          const name = user.Attributes?.find(attr => attr.Name === 'name')?.Value
          return user.Username?.match(regex) || name?.match(regex)
        } else if (filter === 'Role') {
          const userRoles = userGroups.get(user.Username as string)
          return filters[filter].map((role: any) => role.value).filter((role: string) => !userRoles?.has(role as UserGroup)).length === 0
        }
        return user[filter as keyof UserType] === (filters[filter as string])
      })
    }

    filtered.sort((a, b) => {
      const aName = (a.Attributes?.find(attr => attr.Name === 'name')?.Value || a.Username) as string
      const bName = (b.Attributes?.find(attr => attr.Name === 'name')?.Value || b.Username) as string

      return aName < bName ? -1 : 1
    })

    return filtered
  }, [filters, users, userGroups])

  const getUserSettings = useCallback((username: string) => settings?.get(username), [settings])
  const getPushSubscriptions = useCallback((username: string) => tokens?.get(username), [tokens])
  const getUserGroups = useCallback((username: string) => userGroups.get(username), [userGroups])

  if (groups?.size === 0 || settings?.size === 0 || tokens?.size === 0) {
    return (
      <div>
        <h1> Users <FontAwesomeIcon fixedWidth icon={faSpinner} spin /></h1>

        <p>Fetching details</p>
        <p>Loaded groups: {groups?.size}</p>
        <p>Loaded subs: {tokens?.size}</p>
        <p>Loaded settings: {settings?.size}</p>
      </div>
    )
  }
  return (
    <div className="h-full w-full">
      <h1>
        Users {loadingUsers && <FontAwesomeIcon fixedWidth icon={faSpinner} spin />}
      </h1>

      <div className="overflow-y-scroll h-full">
        <table className="divide-y divide-gray-200 table-auto w-full">
          <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <input
                type="search"
                results={filteredUsers.length}
                placeholder="Search by name"
                onChange={(value) => setFilters(prevState => ({...prevState, Username: value.target.value}))}
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              App Version / devices
            </th>
            {/*<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">*/}
            {/*  Status*/}
            {/*</th>*/}
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-no-wrap min-w-48">
              <Select
                className="input-no-ring"
                options={Array.from(groups?.keys() ?? []).map(group => ({value: group, label: group}))}
                placeholder="Role(s)"
                isMulti
                onChange={(value) => setFilters(prevState => ({...prevState, Role: value}))}
                closeMenuOnSelect
                value={filters.Role}
                components={{
                  Option: SelectOption,
                  MultiValueLabel: SelectMultiValueLabel,
                }}
              />
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {filteredUsers.map((user: UserType) =>
            <User
              key={user.Username}
              user={user}
              settings={getUserSettings(user.Username as string)}
              tokens={getPushSubscriptions(user.Username as string)}
              groups={getUserGroups(user.Username as string)}
            />
          )}
          </tbody>
          {loadingUsers && <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td colSpan={5} className="px-6 py-3 text-center text-gray-500 tracking-wider">Loading more users</td>
          </tr>
          </tbody>}
          <tfoot>
          <tr>
            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
              Loaded {users.length} | (filtered {filteredUsers.length})
            </td>
            {(userToken !== undefined || page === 0) &&
              <td ref={userDiv} colSpan={4} className="px-6 py-3 text-right text-xs font-medium text-gray-500 tracking-wider">
                Loading
              </td>
            }
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )

}
export default UserList
