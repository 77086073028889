import {GraphQLResult} from "@aws-amplify/api-graphql"
import {GetUserSettingQuery, UserSetting} from "@graphql/api"
import {getUserSetting} from "@graphql/queries"
import {CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js"
import {API, Auth, graphqlOperation} from "aws-amplify"
import { useEffect, useMemo, useState } from 'react';
import CalLink from "./Dashboard/CalLink"

const Dashboard = () => {
  const [user, setUser] = useState<CognitoUser>()
  const [attributes, setUserAttributes] = useState<Record<string, any>>({})
  const [userSettings, setUserSettings] = useState<UserSetting>()

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      console.debug('User changed', user)
      user.getUserAttributes((err: Error, attribs: Array<CognitoUserAttribute>) => setUserAttributes(attribs.reduce((obj, item) => Object.assign(obj, {[item.Name]: item.Value}), {})))
      setUser(user)
    })
  }, [])

  useEffect(() => {
    if (user) {
      (async () => {
        const {data} = await API.graphql(graphqlOperation(getUserSetting, {owner: user.getUsername()})) as GraphQLResult<GetUserSettingQuery>
        console.debug('Loaded user settings', data)
        if (data?.getUserSetting) {
          console.debug('Set them up', data.getUserSetting)
          setUserSettings(() => data.getUserSetting as UserSetting)
        }
      })();
    }
  }, [user])

  const name = useMemo(() => {
    return attributes?.name ?? user?.getUsername()
  }, [attributes, user])

  if (!user) {
    return null
  }

  return (
    <div className="w-full">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">OverFlight Dashboard</h3>
              <p className="mt-1 text-sm text-gray-600">Hi, {name}!</p>
            </div>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">App Version</legend>
                  <div className="grid grid-cols-6 gap-6 mt-4">
                    <div className="col-span-6 sm:col-span-3">
                      <span className="block text-sm font-medium text-gray-700">
                        <code>{userSettings?.appVersion}</code>
                      </span>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="px-4 py-5 bg-white sm:p-6">
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">Task Calendar</legend>
                  <div className="grid grid-cols-6 gap-6 mt-4">
                    <div className="col-span-6 sm:col-span-3">
                      <span className="block text-sm font-medium text-gray-700">
                        <CalLink settings={userSettings as UserSetting} user={user}/>
                      </span>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
