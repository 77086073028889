/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteBroadcastMessage = /* GraphQL */ `
  mutation DeleteBroadcastMessage(
    $input: DeleteBroadcastMessageInput!
    $condition: ModelBroadcastMessageConditionInput
  ) {
    deleteBroadcastMessage(input: $input, condition: $condition) {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        team {
          nextToken
          startedAt
        }
        manifestItems {
          nextToken
          startedAt
        }
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        teamLeader {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        passengers {
          nextToken
          startedAt
        }
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelSource {
          provider
          name
        }
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      teamMembers {
        items {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      flights {
        items {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      legs {
        items {
          id
          eventId
          order
          venueId
          venueName
          venuePostcode
          empty
          departing
          fromVenueId
          fromVenueName
          fromVenuePostcode
          arriving
          toVenueId
          toVenueName
          toVenuePostcode
          distance
          ete
          lat
          lng
          w3w
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        team {
          nextToken
          startedAt
        }
        manifestItems {
          nextToken
          startedAt
        }
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        teamLeader {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        passengers {
          nextToken
          startedAt
        }
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelSource {
          provider
          name
        }
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      teamMembers {
        items {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      flights {
        items {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      legs {
        items {
          id
          eventId
          order
          venueId
          venueName
          venuePostcode
          empty
          departing
          fromVenueId
          fromVenueName
          fromVenuePostcode
          arriving
          toVenueId
          toVenueName
          toVenuePostcode
          distance
          ete
          lat
          lng
          w3w
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      modelType
      id
      type
      oid
      venue
      venueId
      regionId
      date
      endDate
      pax
      product
      ref
      description
      notes
      overview {
        id
        eventId
        team {
          nextToken
          startedAt
        }
        manifestItems {
          nextToken
          startedAt
        }
        paxBooked
        aircraftReg
        aircraftSeats
        fireName
        fireReg
        bowserName
        truckName
        truckReg
        counts
        teamLeaderId
        teamLeader {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        disabledRoles
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      currentFlight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        passengers {
          nextToken
          startedAt
        }
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      currentRefuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelSource {
          provider
          name
        }
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      teamMembers {
        items {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      flights {
        items {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      legs {
        items {
          id
          eventId
          order
          venueId
          venueName
          venuePostcode
          empty
          departing
          fromVenueId
          fromVenueName
          fromVenuePostcode
          arriving
          toVenueId
          toVenueName
          toVenuePostcode
          distance
          ete
          lat
          lng
          w3w
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      pilotName
      pilotConfirmed
      aircraftId
      aircraftType
      aircraftName
      aircraftSeats
      aircraft
      duration
      client
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventOverviewId
      eventCurrentFlightId
      eventCurrentRefuelRequestId
    }
  }
`;
export const createPleasureOverview = /* GraphQL */ `
  mutation CreatePleasureOverview(
    $input: CreatePleasureOverviewInput!
    $condition: ModelPleasureOverviewConditionInput
  ) {
    createPleasureOverview(input: $input, condition: $condition) {
      id
      eventId
      team {
        items {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      manifestItems {
        items {
          id
          overviewId
          eventId
          time
          duration
          designator
          pax
          weight
          voucherIds
          frontSeatTaken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePleasureOverview = /* GraphQL */ `
  mutation UpdatePleasureOverview(
    $input: UpdatePleasureOverviewInput!
    $condition: ModelPleasureOverviewConditionInput
  ) {
    updatePleasureOverview(input: $input, condition: $condition) {
      id
      eventId
      team {
        items {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      manifestItems {
        items {
          id
          overviewId
          eventId
          time
          duration
          designator
          pax
          weight
          voucherIds
          frontSeatTaken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePleasureOverview = /* GraphQL */ `
  mutation DeletePleasureOverview(
    $input: DeletePleasureOverviewInput!
    $condition: ModelPleasureOverviewConditionInput
  ) {
    deletePleasureOverview(input: $input, condition: $condition) {
      id
      eventId
      team {
        items {
          id
          overviewId
          eventId
          name
          forename
          surname
          teamResourceId
          teamRole
          phone
          confirmed
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      manifestItems {
        items {
          id
          overviewId
          eventId
          time
          duration
          designator
          pax
          weight
          voucherIds
          frontSeatTaken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paxBooked
      aircraftReg
      aircraftSeats
      fireName
      fireReg
      bowserName
      truckName
      truckReg
      counts
      teamLeaderId
      teamLeader {
        id
        overviewId
        eventId
        name
        forename
        surname
        teamResourceId
        teamRole
        phone
        confirmed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      disabledRoles
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember(
    $input: CreateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    createTeamMember(input: $input, condition: $condition) {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember(
    $input: DeleteTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    deleteTeamMember(input: $input, condition: $condition) {
      id
      overviewId
      eventId
      name
      forename
      surname
      teamResourceId
      teamRole
      phone
      confirmed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createManifestItem = /* GraphQL */ `
  mutation CreateManifestItem(
    $input: CreateManifestItemInput!
    $condition: ModelManifestItemConditionInput
  ) {
    createManifestItem(input: $input, condition: $condition) {
      id
      overviewId
      eventId
      time
      duration
      designator
      pax
      weight
      voucherIds
      frontSeatTaken
      passengers {
        title
        forename
        surname
        phone
        email
        postcode
        weight
        enquiryId
        voucherId
        frontSeat
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateManifestItem = /* GraphQL */ `
  mutation UpdateManifestItem(
    $input: UpdateManifestItemInput!
    $condition: ModelManifestItemConditionInput
  ) {
    updateManifestItem(input: $input, condition: $condition) {
      id
      overviewId
      eventId
      time
      duration
      designator
      pax
      weight
      voucherIds
      frontSeatTaken
      passengers {
        title
        forename
        surname
        phone
        email
        postcode
        weight
        enquiryId
        voucherId
        frontSeat
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteManifestItem = /* GraphQL */ `
  mutation DeleteManifestItem(
    $input: DeleteManifestItemInput!
    $condition: ModelManifestItemConditionInput
  ) {
    deleteManifestItem(input: $input, condition: $condition) {
      id
      overviewId
      eventId
      time
      duration
      designator
      pax
      weight
      voucherIds
      frontSeatTaken
      passengers {
        title
        forename
        surname
        phone
        email
        postcode
        weight
        enquiryId
        voucherId
        frontSeat
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFlight = /* GraphQL */ `
  mutation CreateFlight(
    $input: CreateFlightInput!
    $condition: ModelFlightConditionInput
  ) {
    createFlight(input: $input, condition: $condition) {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        items {
          id
          eventId
          tupleId
          enquiryId
          voucherId
          designator
          sequence
          flightNumber
          flightId
          number
          passengerNumber
          forename
          surname
          weight
          seat
          phone
          email
          postcode
          bubbly
          creamTea
          lunch
          magnum
          chocolate
          photo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFlight = /* GraphQL */ `
  mutation UpdateFlight(
    $input: UpdateFlightInput!
    $condition: ModelFlightConditionInput
  ) {
    updateFlight(input: $input, condition: $condition) {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        items {
          id
          eventId
          tupleId
          enquiryId
          voucherId
          designator
          sequence
          flightNumber
          flightId
          number
          passengerNumber
          forename
          surname
          weight
          seat
          phone
          email
          postcode
          bubbly
          creamTea
          lunch
          magnum
          chocolate
          photo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFlight = /* GraphQL */ `
  mutation DeleteFlight(
    $input: DeleteFlightInput!
    $condition: ModelFlightConditionInput
  ) {
    deleteFlight(input: $input, condition: $condition) {
      id
      tupleId
      eventId
      flightOrderId
      designator
      sequence
      flightNumber
      theme
      aircraftName
      isFlightFull
      scheduledDeparture
      actualDeparture
      estimatedArrival
      scheduledCheckIn
      status
      duration
      passengers {
        items {
          id
          eventId
          tupleId
          enquiryId
          voucherId
          designator
          sequence
          flightNumber
          flightId
          number
          passengerNumber
          forename
          surname
          weight
          seat
          phone
          email
          postcode
          bubbly
          creamTea
          lunch
          magnum
          chocolate
          photo
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      updatedAt
      lastUpdatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRefuelRequest = /* GraphQL */ `
  mutation CreateRefuelRequest(
    $input: CreateRefuelRequestInput!
    $condition: ModelRefuelRequestConditionInput
  ) {
    createRefuelRequest(input: $input, condition: $condition) {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRefuelRequest = /* GraphQL */ `
  mutation UpdateRefuelRequest(
    $input: UpdateRefuelRequestInput!
    $condition: ModelRefuelRequestConditionInput
  ) {
    updateRefuelRequest(input: $input, condition: $condition) {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRefuelRequest = /* GraphQL */ `
  mutation DeleteRefuelRequest(
    $input: DeleteRefuelRequestInput!
    $condition: ModelRefuelRequestConditionInput
  ) {
    deleteRefuelRequest(input: $input, condition: $condition) {
      id
      eventId
      requestedBy
      requestFrom
      delay
      users
      flightOrderId
      adventureId
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
      }
      fuelType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRefuel = /* GraphQL */ `
  mutation CreateRefuel(
    $input: CreateRefuelInput!
    $condition: ModelRefuelConditionInput
  ) {
    createRefuel(input: $input, condition: $condition) {
      id
      eventId
      refuelRequestId
      refuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelSource {
          provider
          name
        }
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
      }
      fuelType
      users
      createdAt
      refueledAt
      refueledBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRefuel = /* GraphQL */ `
  mutation UpdateRefuel(
    $input: UpdateRefuelInput!
    $condition: ModelRefuelConditionInput
  ) {
    updateRefuel(input: $input, condition: $condition) {
      id
      eventId
      refuelRequestId
      refuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelSource {
          provider
          name
        }
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
      }
      fuelType
      users
      createdAt
      refueledAt
      refueledBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRefuel = /* GraphQL */ `
  mutation DeleteRefuel(
    $input: DeleteRefuelInput!
    $condition: ModelRefuelConditionInput
  ) {
    deleteRefuel(input: $input, condition: $condition) {
      id
      eventId
      refuelRequestId
      refuelRequest {
        id
        eventId
        requestedBy
        requestFrom
        delay
        users
        flightOrderId
        adventureId
        aircraftId
        aircraftName
        litres
        fuelSource {
          provider
          name
        }
        fuelType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      aircraftId
      aircraftName
      litres
      fuelSource {
        provider
        name
      }
      fuelType
      users
      createdAt
      refueledAt
      refueledBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFlightPassenger = /* GraphQL */ `
  mutation CreateFlightPassenger(
    $input: CreateFlightPassengerInput!
    $condition: ModelFlightPassengerConditionInput
  ) {
    createFlightPassenger(input: $input, condition: $condition) {
      id
      eventId
      tupleId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      enquiryId
      voucherId
      designator
      sequence
      flightNumber
      flightId
      number
      passengerNumber
      forename
      surname
      weight
      seat
      phone
      email
      postcode
      bubbly
      creamTea
      lunch
      magnum
      chocolate
      photo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFlightPassenger = /* GraphQL */ `
  mutation UpdateFlightPassenger(
    $input: UpdateFlightPassengerInput!
    $condition: ModelFlightPassengerConditionInput
  ) {
    updateFlightPassenger(input: $input, condition: $condition) {
      id
      eventId
      tupleId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      enquiryId
      voucherId
      designator
      sequence
      flightNumber
      flightId
      number
      passengerNumber
      forename
      surname
      weight
      seat
      phone
      email
      postcode
      bubbly
      creamTea
      lunch
      magnum
      chocolate
      photo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFlightPassenger = /* GraphQL */ `
  mutation DeleteFlightPassenger(
    $input: DeleteFlightPassengerInput!
    $condition: ModelFlightPassengerConditionInput
  ) {
    deleteFlightPassenger(input: $input, condition: $condition) {
      id
      eventId
      tupleId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      enquiryId
      voucherId
      designator
      sequence
      flightNumber
      flightId
      number
      passengerNumber
      forename
      surname
      weight
      seat
      phone
      email
      postcode
      bubbly
      creamTea
      lunch
      magnum
      chocolate
      photo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFollowingLog = /* GraphQL */ `
  mutation CreateFollowingLog(
    $input: CreateFollowingLogInput!
    $condition: ModelFollowingLogConditionInput
  ) {
    createFollowingLog(input: $input, condition: $condition) {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFollowingLog = /* GraphQL */ `
  mutation UpdateFollowingLog(
    $input: UpdateFollowingLogInput!
    $condition: ModelFollowingLogConditionInput
  ) {
    updateFollowingLog(input: $input, condition: $condition) {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFollowingLog = /* GraphQL */ `
  mutation DeleteFollowingLog(
    $input: DeleteFollowingLogInput!
    $condition: ModelFollowingLogConditionInput
  ) {
    deleteFollowingLog(input: $input, condition: $condition) {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAzureFollowingLog = /* GraphQL */ `
  mutation CreateAzureFollowingLog(
    $input: CreateAzureFollowingLogInput!
    $condition: ModelAzureFollowingLogConditionInput
  ) {
    createAzureFollowingLog(input: $input, condition: $condition) {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAzureFollowingLog = /* GraphQL */ `
  mutation UpdateAzureFollowingLog(
    $input: UpdateAzureFollowingLogInput!
    $condition: ModelAzureFollowingLogConditionInput
  ) {
    updateAzureFollowingLog(input: $input, condition: $condition) {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAzureFollowingLog = /* GraphQL */ `
  mutation DeleteAzureFollowingLog(
    $input: DeleteAzureFollowingLogInput!
    $condition: ModelAzureFollowingLogConditionInput
  ) {
    deleteAzureFollowingLog(input: $input, condition: $condition) {
      modelType
      id
      user
      pilotName
      type
      notes
      eventId
      originId
      originType
      aircraft
      aircraftType
      pob
      action
      origin
      destination
      d_lat
      d_lng
      accuracy
      altitude
      altitudeAccuracy
      lat
      lng
      eta
      ete
      gs
      hdg
      battery
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFlightLeg = /* GraphQL */ `
  mutation CreateFlightLeg(
    $input: CreateFlightLegInput!
    $condition: ModelFlightLegConditionInput
  ) {
    createFlightLeg(input: $input, condition: $condition) {
      id
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      order
      venueId
      venueName
      venuePostcode
      empty
      departing
      fromVenueId
      fromVenueName
      fromVenuePostcode
      fromCoords {
        lat
        lng
      }
      arriving
      toVenueId
      toVenueName
      toVenuePostcode
      toCoords {
        lat
        lng
      }
      distance
      ete
      lat
      lng
      w3w
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFlightLeg = /* GraphQL */ `
  mutation UpdateFlightLeg(
    $input: UpdateFlightLegInput!
    $condition: ModelFlightLegConditionInput
  ) {
    updateFlightLeg(input: $input, condition: $condition) {
      id
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      order
      venueId
      venueName
      venuePostcode
      empty
      departing
      fromVenueId
      fromVenueName
      fromVenuePostcode
      fromCoords {
        lat
        lng
      }
      arriving
      toVenueId
      toVenueName
      toVenuePostcode
      toCoords {
        lat
        lng
      }
      distance
      ete
      lat
      lng
      w3w
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFlightLeg = /* GraphQL */ `
  mutation DeleteFlightLeg(
    $input: DeleteFlightLegInput!
    $condition: ModelFlightLegConditionInput
  ) {
    deleteFlightLeg(input: $input, condition: $condition) {
      id
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      order
      venueId
      venueName
      venuePostcode
      empty
      departing
      fromVenueId
      fromVenueName
      fromVenuePostcode
      fromCoords {
        lat
        lng
      }
      arriving
      toVenueId
      toVenueName
      toVenuePostcode
      toCoords {
        lat
        lng
      }
      distance
      ete
      lat
      lng
      w3w
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPushSubscription = /* GraphQL */ `
  mutation CreatePushSubscription(
    $input: CreatePushSubscriptionInput!
    $condition: ModelPushSubscriptionConditionInput
  ) {
    createPushSubscription(input: $input, condition: $condition) {
      user
      channel
      deviceToken
      deviceType
      expoPushToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePushSubscription = /* GraphQL */ `
  mutation UpdatePushSubscription(
    $input: UpdatePushSubscriptionInput!
    $condition: ModelPushSubscriptionConditionInput
  ) {
    updatePushSubscription(input: $input, condition: $condition) {
      user
      channel
      deviceToken
      deviceType
      expoPushToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePushSubscription = /* GraphQL */ `
  mutation DeletePushSubscription(
    $input: DeletePushSubscriptionInput!
    $condition: ModelPushSubscriptionConditionInput
  ) {
    deletePushSubscription(input: $input, condition: $condition) {
      user
      channel
      deviceToken
      deviceType
      expoPushToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserSetting = /* GraphQL */ `
  mutation CreateUserSetting(
    $input: CreateUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    createUserSetting(input: $input, condition: $condition) {
      teamLeaderRegionId
      teamLeaderRegionIds
      teamResourceId
      owner
      profileImage
      callsign
      calCode
      appVersion
      weightDisplay
      accessibilityIcons
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserSetting = /* GraphQL */ `
  mutation UpdateUserSetting(
    $input: UpdateUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    updateUserSetting(input: $input, condition: $condition) {
      teamLeaderRegionId
      teamLeaderRegionIds
      teamResourceId
      owner
      profileImage
      callsign
      calCode
      appVersion
      weightDisplay
      accessibilityIcons
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserSetting = /* GraphQL */ `
  mutation DeleteUserSetting(
    $input: DeleteUserSettingInput!
    $condition: ModelUserSettingConditionInput
  ) {
    deleteUserSetting(input: $input, condition: $condition) {
      teamLeaderRegionId
      teamLeaderRegionIds
      teamResourceId
      owner
      profileImage
      callsign
      calCode
      appVersion
      weightDisplay
      accessibilityIcons
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCertificate = /* GraphQL */ `
  mutation CreateCertificate(
    $input: CreateCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    createCertificate(input: $input, condition: $condition) {
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCertificate = /* GraphQL */ `
  mutation UpdateCertificate(
    $input: UpdateCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    updateCertificate(input: $input, condition: $condition) {
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCertificate = /* GraphQL */ `
  mutation DeleteCertificate(
    $input: DeleteCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    deleteCertificate(input: $input, condition: $condition) {
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserCertificate = /* GraphQL */ `
  mutation CreateUserCertificate(
    $input: CreateUserCertificateInput!
    $condition: ModelUserCertificateConditionInput
  ) {
    createUserCertificate(input: $input, condition: $condition) {
      key
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserCertificate = /* GraphQL */ `
  mutation UpdateUserCertificate(
    $input: UpdateUserCertificateInput!
    $condition: ModelUserCertificateConditionInput
  ) {
    updateUserCertificate(input: $input, condition: $condition) {
      key
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserCertificate = /* GraphQL */ `
  mutation DeleteUserCertificate(
    $input: DeleteUserCertificateInput!
    $condition: ModelUserCertificateConditionInput
  ) {
    deleteUserCertificate(input: $input, condition: $condition) {
      key
      owner
      model
      type
      number
      aircraft
      pdf
      expiresAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBroadcastMessage = /* GraphQL */ `
  mutation CreateBroadcastMessage(
    $input: CreateBroadcastMessageInput!
    $condition: ModelBroadcastMessageConditionInput
  ) {
    createBroadcastMessage(input: $input, condition: $condition) {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBroadcastMessage = /* GraphQL */ `
  mutation UpdateBroadcastMessage(
    $input: UpdateBroadcastMessageInput!
    $condition: ModelBroadcastMessageConditionInput
  ) {
    updateBroadcastMessage(input: $input, condition: $condition) {
      id
      modelType
      message
      title
      originType
      originId
      sentBy
      url
      extra
      targetedGroup
      targetedRole
      targetedEventId
      targetedEvent {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      targetedUsers
      users
      broadcastAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBroadcastReceipt = /* GraphQL */ `
  mutation CreateBroadcastReceipt(
    $input: CreateBroadcastReceiptInput!
    $condition: ModelBroadcastReceiptConditionInput
  ) {
    createBroadcastReceipt(input: $input, condition: $condition) {
      id
      broadcastMessageId
      broadcastMessage {
        id
        modelType
        message
        title
        originType
        originId
        sentBy
        url
        extra
        targetedGroup
        targetedRole
        targetedEventId
        targetedEvent {
          modelType
          id
          type
          oid
          venue
          venueId
          regionId
          date
          endDate
          pax
          product
          ref
          description
          notes
          users
          pilotName
          pilotConfirmed
          aircraftId
          aircraftType
          aircraftName
          aircraftSeats
          aircraft
          duration
          client
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventOverviewId
          eventCurrentFlightId
          eventCurrentRefuelRequestId
        }
        targetedUsers
        users
        broadcastAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner
      pushToken
      deviceToken
      sentAt
      status
      message
      details
      deliveredAt
      errors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBroadcastReceipt = /* GraphQL */ `
  mutation UpdateBroadcastReceipt(
    $input: UpdateBroadcastReceiptInput!
    $condition: ModelBroadcastReceiptConditionInput
  ) {
    updateBroadcastReceipt(input: $input, condition: $condition) {
      id
      broadcastMessageId
      broadcastMessage {
        id
        modelType
        message
        title
        originType
        originId
        sentBy
        url
        extra
        targetedGroup
        targetedRole
        targetedEventId
        targetedEvent {
          modelType
          id
          type
          oid
          venue
          venueId
          regionId
          date
          endDate
          pax
          product
          ref
          description
          notes
          users
          pilotName
          pilotConfirmed
          aircraftId
          aircraftType
          aircraftName
          aircraftSeats
          aircraft
          duration
          client
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventOverviewId
          eventCurrentFlightId
          eventCurrentRefuelRequestId
        }
        targetedUsers
        users
        broadcastAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner
      pushToken
      deviceToken
      sentAt
      status
      message
      details
      deliveredAt
      errors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBroadcastReceipt = /* GraphQL */ `
  mutation DeleteBroadcastReceipt(
    $input: DeleteBroadcastReceiptInput!
    $condition: ModelBroadcastReceiptConditionInput
  ) {
    deleteBroadcastReceipt(input: $input, condition: $condition) {
      id
      broadcastMessageId
      broadcastMessage {
        id
        modelType
        message
        title
        originType
        originId
        sentBy
        url
        extra
        targetedGroup
        targetedRole
        targetedEventId
        targetedEvent {
          modelType
          id
          type
          oid
          venue
          venueId
          regionId
          date
          endDate
          pax
          product
          ref
          description
          notes
          users
          pilotName
          pilotConfirmed
          aircraftId
          aircraftType
          aircraftName
          aircraftSeats
          aircraft
          duration
          client
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventOverviewId
          eventCurrentFlightId
          eventCurrentRefuelRequestId
        }
        targetedUsers
        users
        broadcastAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      owner
      pushToken
      deviceToken
      sentAt
      status
      message
      details
      deliveredAt
      errors
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createExperiment = /* GraphQL */ `
  mutation CreateExperiment(
    $input: CreateExperimentInput!
    $condition: ModelExperimentConditionInput
  ) {
    createExperiment(input: $input, condition: $condition) {
      owner
      experiment
      enabled
      extra
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateExperiment = /* GraphQL */ `
  mutation UpdateExperiment(
    $input: UpdateExperimentInput!
    $condition: ModelExperimentConditionInput
  ) {
    updateExperiment(input: $input, condition: $condition) {
      owner
      experiment
      enabled
      extra
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteExperiment = /* GraphQL */ `
  mutation DeleteExperiment(
    $input: DeleteExperimentInput!
    $condition: ModelExperimentConditionInput
  ) {
    deleteExperiment(input: $input, condition: $condition) {
      owner
      experiment
      enabled
      extra
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPhotoAsset = /* GraphQL */ `
  mutation CreatePhotoAsset(
    $input: CreatePhotoAssetInput!
    $condition: ModelPhotoAssetConditionInput
  ) {
    createPhotoAsset(input: $input, condition: $condition) {
      id
      file {
        bucket
        region
        key
      }
      files {
        file {
          bucket
          region
          key
        }
      }
      enquiryId
      passengers
      email
      flightId
      flight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        passengers {
          nextToken
          startedAt
        }
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      salesAtEvent {
        physical {
          copies
          delivered
        }
        digital {
          copies
          delivered
        }
      }
      salesAfterEvent {
        physical {
          copies
          delivered
        }
        digital {
          copies
          delivered
        }
      }
      soldBy
      emailSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePhotoAsset = /* GraphQL */ `
  mutation UpdatePhotoAsset(
    $input: UpdatePhotoAssetInput!
    $condition: ModelPhotoAssetConditionInput
  ) {
    updatePhotoAsset(input: $input, condition: $condition) {
      id
      file {
        bucket
        region
        key
      }
      files {
        file {
          bucket
          region
          key
        }
      }
      enquiryId
      passengers
      email
      flightId
      flight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        passengers {
          nextToken
          startedAt
        }
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      salesAtEvent {
        physical {
          copies
          delivered
        }
        digital {
          copies
          delivered
        }
      }
      salesAfterEvent {
        physical {
          copies
          delivered
        }
        digital {
          copies
          delivered
        }
      }
      soldBy
      emailSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePhotoAsset = /* GraphQL */ `
  mutation DeletePhotoAsset(
    $input: DeletePhotoAssetInput!
    $condition: ModelPhotoAssetConditionInput
  ) {
    deletePhotoAsset(input: $input, condition: $condition) {
      id
      file {
        bucket
        region
        key
      }
      files {
        file {
          bucket
          region
          key
        }
      }
      enquiryId
      passengers
      email
      flightId
      flight {
        id
        tupleId
        eventId
        flightOrderId
        designator
        sequence
        flightNumber
        theme
        aircraftName
        isFlightFull
        scheduledDeparture
        actualDeparture
        estimatedArrival
        scheduledCheckIn
        status
        duration
        passengers {
          nextToken
          startedAt
        }
        users
        updatedAt
        lastUpdatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      eventId
      event {
        modelType
        id
        type
        oid
        venue
        venueId
        regionId
        date
        endDate
        pax
        product
        ref
        description
        notes
        overview {
          id
          eventId
          paxBooked
          aircraftReg
          aircraftSeats
          fireName
          fireReg
          bowserName
          truckName
          truckReg
          counts
          teamLeaderId
          disabledRoles
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        currentFlight {
          id
          tupleId
          eventId
          flightOrderId
          designator
          sequence
          flightNumber
          theme
          aircraftName
          isFlightFull
          scheduledDeparture
          actualDeparture
          estimatedArrival
          scheduledCheckIn
          status
          duration
          users
          updatedAt
          lastUpdatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        currentRefuelRequest {
          id
          eventId
          requestedBy
          requestFrom
          delay
          users
          flightOrderId
          adventureId
          aircraftId
          aircraftName
          litres
          fuelType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMembers {
          nextToken
          startedAt
        }
        flights {
          nextToken
          startedAt
        }
        legs {
          nextToken
          startedAt
        }
        users
        pilotName
        pilotConfirmed
        aircraftId
        aircraftType
        aircraftName
        aircraftSeats
        aircraft
        duration
        client
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventOverviewId
        eventCurrentFlightId
        eventCurrentRefuelRequestId
      }
      salesAtEvent {
        physical {
          copies
          delivered
        }
        digital {
          copies
          delivered
        }
      }
      salesAfterEvent {
        physical {
          copies
          delivered
        }
        digital {
          copies
          delivered
        }
      }
      soldBy
      emailSent
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSafetyComm = /* GraphQL */ `
  mutation CreateSafetyComm(
    $input: CreateSafetyCommInput!
    $condition: ModelSafetyCommConditionInput
  ) {
    createSafetyComm(input: $input, condition: $condition) {
      id
      title
      modelType
      url
      file {
        bucket
        region
        key
      }
      publishedBy
      publishedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSafetyComm = /* GraphQL */ `
  mutation UpdateSafetyComm(
    $input: UpdateSafetyCommInput!
    $condition: ModelSafetyCommConditionInput
  ) {
    updateSafetyComm(input: $input, condition: $condition) {
      id
      title
      modelType
      url
      file {
        bucket
        region
        key
      }
      publishedBy
      publishedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSafetyComm = /* GraphQL */ `
  mutation DeleteSafetyComm(
    $input: DeleteSafetyCommInput!
    $condition: ModelSafetyCommConditionInput
  ) {
    deleteSafetyComm(input: $input, condition: $condition) {
      id
      title
      modelType
      url
      file {
        bucket
        region
        key
      }
      publishedBy
      publishedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
