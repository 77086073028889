import {DateTime} from "luxon"
import { useEffect, useState } from 'react';

export default function Timer({time}: { time: DateTime }) {
  const [display, setDisplay] = useState<string | null>(time.toRelative())

  useEffect(() => {
    const tick = () => {
      setDisplay(time.toRelative())
    }

    const id = setInterval(tick, 1000)
    return () => clearInterval(id)
  })

  return (
    <span>{display}</span>
  )
}
