import { useEffect, useMemo } from 'react';
import {useParams} from 'react-router-dom';

const DocumentLinks = () => {
  const {doc} = useParams<{doc: string}>();

  const docLink = useMemo<string | undefined>(() => {
    if (doc === 'safety.pdf') {
      return 'https://adv001storage.blob.core.windows.net/public/partners/documents/NHL-SMS%20Manual-Part-CAMO-Iss3-Rev0.pdf';
    }
  }, [doc]);

  useEffect(() => {
    if (docLink) {
      window.location.href = docLink;
    }
  }, [docLink]);

  return (
    <p>Redirecting...</p>
  );
};

export default DocumentLinks;
