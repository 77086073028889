import {CognitoUser} from "amazon-cognito-identity-js"
import {API, Auth} from "aws-amplify"

export default class AdminQueries {
  private static instance: AdminQueries

  // private static cache: any

  public static getInstance(): AdminQueries {
    if (!this.instance) {
      this.instance = new this()
    }

    return this.instance
  }

  private static async getInit(): Promise<object> {
    return {
      headers: {
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
  }

  public async listUsers(token?: string, filter?: string): Promise<{users: Array<CognitoUser>, token?: string}> {
    const params = new URLSearchParams()
    token && params.set('token', token)
    filter && params.set('filter', filter)

    const {Users, NextToken} = await API.get('AdminQueries', `/listUsers?${params}`, await AdminQueries.getInit())

    return {users: Users, token: NextToken}
  }

  public async listGroups(): Promise<{groups: Array<any>}> {
    const {Groups} = await API.get('AdminQueries', '/listGroups', await AdminQueries.getInit())

    return {groups: Groups}
  }

  public async listUsersInGroup(group: string, token?: string | null): Promise<{users: Array<CognitoUser>, token?: string | undefined | null}> {
    const params = new URLSearchParams();
    params.set('groupname', group)
    token && params.set('token', token)

    const {Users, NextToken} = await API.get('AdminQueries', `/listUsersInGroup?${params}`, await AdminQueries.getInit())

    return {users: Users, token: NextToken}
  }
}
