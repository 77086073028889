import {GRAPHQL_AUTH_MODE, GraphQLResult} from '@aws-amplify/api-graphql';
import {faBellPlus, faChevronRight} from '@fortawesome/pro-duotone-svg-icons';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DeleteSafetyCommMutation, DeleteSafetyCommMutationVariables, ListSafetyCommsByPublishedAtQuery, ModelSortDirection, SafetyComm, SafetyCommString} from '@graphql/api';
import {deleteSafetyComm} from '@graphql/mutations';
import {listSafetyCommsByPublishedAt} from '@graphql/queries';
import {API} from 'aws-amplify';
import {orderBy} from 'lodash-es';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Row from './_Row';

export enum TeamMemberRole {
  captain = 'Captain',
  ops1 = 'Ops',
  rffs1 = 'RFFS',
  photos1 = 'Photos',
}

const List = () => {
  const [safetyComms, setSafetyComms] = useState<Array<SafetyComm>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | null>();

  const loadSafetyComms = async (loadMore: boolean = false) => {
    if (isLoading || (loadMore && !nextToken)) {
      return;
    }

    setLoading(true);
    let items: Array<any> = [];

    const {data} = await API.graphql({
      query: listSafetyCommsByPublishedAt,
      variables: {
        modelType: SafetyCommString.safetycomm,
        sortDirection: ModelSortDirection.DESC,
        limit: 100,
        nextToken,
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }) as GraphQLResult<ListSafetyCommsByPublishedAtQuery>;

    if (data?.listSafetyCommsByPublishedAt?.items) {
      setNextToken(data?.listSafetyCommsByPublishedAt.nextToken);
      items = items.concat(data?.listSafetyCommsByPublishedAt.items);
    }

    setSafetyComms(prevState => orderBy([...prevState, ...items], ['createdAt'], ['desc']));
    setLoading(false);
  };

  const onDelete = async (id: string) => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    const {data, errors} = await API.graphql({
      query: deleteSafetyComm,
      variables: {
        input: {
          id,
          _version: 1,
        }
      } as DeleteSafetyCommMutationVariables,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }) as GraphQLResult<DeleteSafetyCommMutation>;

    if (errors) {
      console.error(errors);
      return;
    }

    setSafetyComms(prevState => prevState.filter(safetyComm => safetyComm.id !== id));
    setLoading(false);
  }

  useEffect(() => {
    setSafetyComms([]);
    loadSafetyComms();
  }, []);

  return (
    <div className="w-full">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0 w-full flex flex-row items-center">
              <span className="flex-1 my-2">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Safety Comms</h3>
                <p className="mt-1 text-sm text-gray-600">View all prior Safety Comms</p>
              </span>
              {isLoading && <FontAwesomeIcon fixedWidth icon={faSpinner} size="lg" spin className="mr-2" />}

              <Link to="/admin/safety-comms/create" className="flex-0 uppercase text-xs text-blue-400 rounded border-blue-400 border p-2" style={{height: 'fit-content'}}>
                <FontAwesomeIcon fixedWidth icon={faBellPlus} className="mr-2" />
                Create Safety Com
              </Link>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  URL
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Publisher
                </th>
                <th scope="col" className="px-6 py-3 tracking-wider whitespace-nowrap" />
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {safetyComms.map(safetyComm => <Row key={safetyComm.id} safetyComm={safetyComm} onDelete={onDelete} isDisabled={isLoading} />)}
              </tbody>

              {safetyComms.length === 0 && (
                <tbody>
                <tr>
                  <th colSpan={4} className="p-4 pt-8">
                    <div className="flex justify-center">
                      <p className="text-gray-400">No Safety Comms found.</p>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colSpan={4} className="p-4 pb-8">
                    <Link to="/admin/safety-comms/create" className="flex-0 uppercase text-xs text-blue-400 rounded border-blue-400 border p-2" style={{height: 'fit-content'}}>
                      <FontAwesomeIcon fixedWidth icon={faBellPlus} className="mr-2" />
                      Create Safety Com
                    </Link>
                  </th>
                </tr>
                </tbody>
              )}

              <tfoot>
              <tr>
                <th colSpan={4}>
                  <div className="flex justify-center">
                    <button className="px-6 py-4 rounded transition hover:ring ring-indigo" onClick={() => loadSafetyComms(true)}>
                      <FontAwesomeIcon fixedWidth icon={faChevronRight} /> More
                    </button>
                  </div>
                </th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
