import {faChevronsLeft, faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchableFollowingLogSortableFields, SearchableSortDirection} from "@graphql/api";
import {searchFollowingLogs} from "@graphql/queries";
import {API, graphqlOperation} from "aws-amplify";
import { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import FollowingLogGroup from "./FollowingLogGroup";

const FollowingLogAircraft = () => {
  const params = useParams();
  const {aircraft} = params as { aircraft: string };
  const [aircraftLogs, setAircraftLogs] = useState([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const loadAircraftLogs = async () => {
    const data = await API.graphql(graphqlOperation(searchFollowingLogs, {
      filter: {
        aircraft: {
          eq: aircraft
        }
      },
      sort: [{
        field: SearchableFollowingLogSortableFields.createdAt,
        direction: SearchableSortDirection.desc
      }],
      limit: 100,
    }));

    if (data && (data as any).data) {
      const logs = (data as any).data.searchFollowingLogs.items
      setAircraftLogs(logs)
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadAircraftLogs()
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <div className="flex flex-row items-center">
        <Link to="/following" className="p-1 m-1 rounded border border-transparent hover:border-black transition">
          <FontAwesomeIcon icon={faChevronsLeft} fixedWidth/> Back
        </Link>

        <h1 className="text-3xl flex-grow">
          {aircraft}
        </h1>
      </div>

      {isLoading
        ? <FontAwesomeIcon icon={faSpinner} spin fixedWidth size="3x"/>
        : <FollowingLogGroup logs={aircraftLogs}/>
      }
    </div>
  )
}

export default FollowingLogAircraft;
