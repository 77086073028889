import {faTrash} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SafetyComm} from '@graphql/api';
import {DateTime} from 'luxon';
import {Fragment, memo} from 'react';

type Props = {
  safetyComm: SafetyComm;
  onDelete: (id: string) => void;
  isDisabled: boolean;
};

const Row = ({safetyComm, onDelete, isDisabled}: Props) => {
  return (
    <Fragment key={safetyComm.id}>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            <span className="capitalize">{safetyComm.title}</span>
          </div>
          <div className="text-sm text-gray-500">{safetyComm.publishedAt ? DateTime.fromISO(safetyComm.publishedAt).toLocaleString(DateTime.DATETIME_FULL) : null}</div>
        </td>
        <td className="px-6 py-4">
          <div className="text-sm font-bold text-gray-900">{safetyComm.title || 'New broadcast'}</div>
          <div className="text-sm text-gray-900">{safetyComm.url}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{safetyComm.publishedBy}</td>
        <td>
          <button disabled={isDisabled} className="px-6 py-4 rounded transition hover:ring ring-indigo" onClick={() => onDelete(safetyComm.id)}>
            <FontAwesomeIcon fixedWidth size="lg" icon={faTrash} color="#f00000"/>
          </button>
        </td>
      </tr>
    </Fragment>
  );
};

export default memo(Row);
