/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const stagingConfig = {
  "aws_project_region": "eu-west-1",
  "aws_cognito_identity_pool_id": "eu-west-1:1348c0fc-6e6e-451c-afd9-37dce9fe9916",
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_KUsef07yA",
  "aws_user_pools_web_client_id": "620nkvq2qlornmedm7nbb9mh82",
  "oauth": {},
  "aws_cloud_logic_custom": [
    {
      "name": "pilot001Rest",
      "endpoint": "https://api.pilotmanifest.app/staging",
      "region": "eu-west-1"
    },
    {
      "name": "AdminQueries",
      "endpoint": "https://plh7h7p7q8.execute-api.eu-west-1.amazonaws.com/staging",
      "region": "eu-west-1"
    }
  ],
  "aws_appsync_graphqlEndpoint": "https://x6cvk5xxgbbtfbfmv73du64liy.appsync-api.eu-west-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": "da2-6lhewwgukrhajbewgq4fy4uorq"
};

export const prodConfig = {
  "aws_project_region": "eu-west-1",
  "aws_cognito_identity_pool_id": "eu-west-1:7bb981ea-ee9b-4a89-b995-fb323f038b66",
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_6KkwdGnAc",
  "aws_user_pools_web_client_id": "3o49d140ag3p5rhss7ht6ht7gr",
  "oauth": {},
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OPTIONAL",
  "aws_cognito_mfa_types": [
    "TOTP"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": "8",
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_cloud_logic_custom": [
    {
      "name": "pilot001Rest",
      "endpoint": "https://api.overflightapp.com",
      "region": "eu-west-1"
    },
    {
      "name": "AdminQueries",
      "endpoint": "https://admin-api.overflightapp.com",
      "region": "eu-west-1"
    }
  ],
  "aws_appsync_graphqlEndpoint": "https://vubje7fypbfofgr66h6aifea4e.appsync-api.eu-west-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-xwg2obij45dldmljbdgknmwpfe",
  "aws_user_files_s3_bucket": "overflight-storage222549-prod",
  "aws_user_files_s3_bucket_region": "eu-west-1",
  "aws_cognito_login_mechanisms": [
    "PREFERRED_USERNAME"
  ]
};

export default prodConfig;
