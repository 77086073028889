import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api-graphql';
import {faListUl} from '@fortawesome/pro-duotone-svg-icons';
import {faEnvelopeOpenText, faSpinner, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BroadcastMessageString} from '@graphql/api';
import {createBroadcastMessage} from '@graphql/mutations';
import {listEventsByDate, searchEvents} from '@graphql/queries';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {UserType} from 'aws-sdk/clients/cognitoidentityserviceprovider';
import debounce from 'debounce-promise';
import {DateTime} from 'luxon';
import { FormEvent, useEffect, useState } from 'react';
import {Store} from 'react-notifications-component';
import {Link} from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import CreateableSelect from 'react-select/creatable';
import AdminQueries from '../UserList/AdminQueries';

export enum TeamMemberRole {
  captain = 'Captain',
  ops1 = 'Ops',
  rffs1 = 'RFFS',
  photos1 = 'Photos',
}

const Create = () => {
  const [groups, setGroups] = useState<Array<any>>([]);
  const [form, setForm] = useState<Record<string, any>>({});
  const [tasks, setTasks] = useState<Array<any>>([]);
  const roles = Object.values(TeamMemberRole);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingTasks, setLoadingTasks] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);

  // @ts-ignore
  const sendMessage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }
    setLoading(true);

    try {
      const user = await Auth.currentUserInfo();
      // @ts-ignore
      const attributes = user.attributes;
      const sentBy = attributes.name;

      const variables = {
        input: {
          originType: 'manual',
          originId: user.username,
          modelType: BroadcastMessageString.broadcast,
          sentBy,
          title: form?.title?.value ?? 'New broadcast',
          message: form.message,
          targetedGroup: form.group,
          targetedEventId: form.task?.id,
          targetedUsers: form.user ? form.user?.map((user: UserType) => user.Username) : undefined,
        }
      };

      const response = await API.graphql(graphqlOperation(createBroadcastMessage, variables)) as any;

      console.debug('Sending message', response);

      if (response) {
        if (response.data?.errors) {
          Store.addNotification({
            message: 'Error sending broadcast',
            title: 'Error',
            type: 'danger',
            container: 'bottom-center'
          });
        } else {
          setForm(() => ({}));

          Store.addNotification({
            message: 'Broadcast sent!',
            title: 'Success',
            type: 'success',
            container: 'bottom-center',
            dismiss: {
              duration: 5000
            }
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const updateForm = (key: string, value: any) => {
    setForm(prevState => ({...prevState, [key]: value}));
  };

  const fetchGroups = async () => {
    setGroups((await AdminQueries.getInstance().listGroups()).groups);
  };

  const loadUsers = async (inputValue: string | null = null) => {
    setLoadingUsers(true);

    if (inputValue) {
      return (await AdminQueries.getInstance().listUsers(undefined, `name^="${inputValue}"`).finally(() => setLoadingUsers(false))).users;
    }

    return [];
  };

  const debounceLoadUsers = debounce(loadUsers, 1000);

  useEffect(() => {
    fetchGroups();
  }, []);

  const selectUserLabel = (user: UserType): string => {
    if (user.Attributes) {
      const attr = user.Attributes.find(attr => attr.Name === 'name');
      if (attr) {
        return `${attr.Value} (${user.Username})`;
      }
    }
    return user.Username || '';
  };

  const loadTasks = async (inputValue: string | null = null) => {
    if (inputValue === null && (loadingTasks || tasks.length > 0)) {
      return;
    }
    setLoadingTasks(true);

    if (inputValue) {
      const response = await API.graphql({
        query: searchEvents,
        variables: {
          filter: {
            or: [
              {venue: {matchPhrase: inputValue}},
              {aircraftName: {matchPhrase: inputValue}},
              {pilotName: {matchPhrase: inputValue}},
              {date: {matchPhrase: inputValue}},
            ],
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      }) as any;

      setLoadingTasks(false);
      console.debug('BROADCAST RESPONSE', response);
      return response.data?.searchEvents?.items || [];
    }

    const {data} = await API.graphql({
      query: listEventsByDate,
      variables: {
        modelType: 'task',
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }) as any;

    if (data?.listEventsByDate?.items) {
      setTasks(() => data.listEventsByDate.items);
    }

    setLoadingTasks(false);
    return data?.listeventsByDate?.items || [];
  };

  useEffect(() => {
    setForm(prevState => ({...prevState, group: undefined, role: undefined, task: undefined, user: undefined}));
  }, [form?.target]);

  return (
    <div className="w-full">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0 w-full flex flex-row">
              <span className="flex-1 my-2">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Broadcast Safety messages</h3>
                <p className="mt-1 text-sm text-gray-600">Send a push notification to a select group or all users at once</p>
              </span>

              <Link to="/admin/broadcasts" className="flex-0 uppercase text-xs text-blue-400 rounded border-blue-400 border p-2" style={{height: 'fit-content'}}>
                <FontAwesomeIcon fixedWidth icon={faListUl} className="mr-2" />
                Back to the list
              </Link>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="/admin/broadcast/send" method="POST" onSubmit={sendMessage}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-2 bg-white sm:p-6 sm:py-3">
                  <fieldset>
                    <legend className="text-base font-medium text-gray-900">Broadcast type</legend>
                    <div className="grid grid-cols-6 gap-6 mt-4">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                          Select or type your own
                        </label>

                        <CreateableSelect
                          defaultInputValue="New broadcast"
                          filterOption={() => true}
                          className="input-no-ring"
                          closeMenuOnSelect
                          value={form.title}
                          onChange={(value) => updateForm('title', value)}
                          options={[{label: 'New broadcast', value: 'New broadcast'}, {label: 'Safety broadcast', value: 'Safety broadcast'}]}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="px-4 py-2 bg-white sm:p-6 sm:py-3">
                  <fieldset>
                    <legend className="text-base font-medium text-gray-900">Content</legend>
                    <div className="grid grid-cols-6 gap-6 mt-4">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                          Message
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(event) => updateForm('message', event.target.value)}
                          value={form.message || ''}
                          placeholder="Enter your message to broadcast"
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="px-4 py-2 bg-white space-y-6 sm:p-6 sm:py-3">
                  <fieldset>
                    <legend className="text-base font-medium text-gray-900 w-full flex flex-row">
                      <span className="flex-1 my-2">
                      Target audience
                      </span>
                      {form.target &&
                        <button onClick={() => setForm(prevState => ({...prevState, target: undefined}))} className="uppercase text-xs text-blue-400 rounded border-blue-400 border p-2">
                          <FontAwesomeIcon fixedWidth icon={faTrash} className="mr-2" />
                          Clear
                        </button>
                      }
                    </legend>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="group"
                            name="target"
                            type="radio"
                            value="group"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            onChange={() => setForm(prevState => ({...prevState, target: 'group'}))}
                            checked={form.target === 'group'}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                          <div className="ml-3 text-sm">
                            <label htmlFor="group" className="font-medium text-gray-700">
                              User Group
                            </label>
                            <p className="text-gray-500">Send to a specific user group</p>
                          </div>
                          <div className={`${form?.target !== 'group' && 'hidden'}`}>
                            <div className="ml-3 text-sm">
                              <select
                                value={form.group} onChange={(event) => updateForm('group', event.target.value)}
                                className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                defaultValue={undefined}
                              >
                                <option value={undefined} disabled>Select a group</option>
                                {groups.map(group => <option value={group.GroupName} key={group.GroupName}>{group.GroupName}</option>)}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-start hidden">
                        <div className="flex items-center h-5">
                          <input
                            id="role"
                            name="target"
                            type="radio"
                            value="role"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            onChange={() => setForm(prevState => ({...prevState, target: 'role'}))}
                            checked={form.target === 'role'}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                          <div className="ml-3 text-sm">
                            <label htmlFor="role" className="font-medium text-gray-700">
                              Team Member Role
                            </label>
                            <p className="text-gray-500">Send to a specific team role</p>
                          </div>
                          <div className={`${form?.target !== 'role' && 'hidden'}`}>
                            <div className="ml-3 text-sm">
                              <select
                                value={form.role} onChange={(event) => updateForm('role', event.target.value)}
                                className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                {roles.map(role => <option value={role} key={role}>{role}</option>)}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="task"
                            name="target"
                            type="radio"
                            value="task"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            onChange={() => setForm(prevState => ({...prevState, target: 'task'}))}
                            checked={form.target === 'task'}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                          <div className="ml-3 text-sm">
                            <label htmlFor="task" className="font-medium text-gray-700">
                              Event/Task
                            </label>
                            <p className="text-gray-500">Send to all team members assigned to a specific event</p>
                          </div>
                          <div className={`${form?.target !== 'task' && 'hidden'}`}>
                            <div className="ml-3 text-sm">
                              <AsyncSelect
                                className="input-no-ring"
                                backspaceRemovesValue
                                closeMenuOnSelect
                                value={form.task}
                                onChange={(value) => updateForm('task', value)}
                                loadOptions={(inputValue) => loadTasks(inputValue)}
                                getOptionLabel={task => `${DateTime.fromISO(task.date).toLocaleString(DateTime.DATETIME_SHORT)} ${task.aircraftName}: ${task.type} (${task.venue})`}
                                getOptionValue={task => task.id}
                                isLoading={loadingTasks}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="user"
                            name="target"
                            type="radio"
                            value="user"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            onChange={() => setForm(prevState => ({...prevState, target: 'user'}))}
                            checked={form.target === 'user'}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                          <div className="ml-3 text-sm">
                            <label htmlFor="user" className="font-medium text-gray-700">
                              User
                            </label>
                            <p className="text-gray-500">Send to a specific user</p>
                          </div>
                          <div className={`${form?.target !== 'user' && 'hidden'}`}>
                            <div className="ml-3 text-sm">
                              <AsyncSelect
                                cacheOptions
                                className="input-no-ring"
                                backspaceRemovesValue
                                closeMenuOnSelect
                                isMulti
                                value={form.user}
                                onChange={(value) => updateForm('user', value)}
                                loadOptions={(inputValue) => debounceLoadUsers(inputValue)}
                                getOptionLabel={(user) => selectUserLabel(user)}
                                getOptionValue={user => user.Username}
                                isLoading={loadingUsers}
                                isClearable
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={!form.message || isLoading}
                  >
                    {isLoading ? (
                      <span>Loading <FontAwesomeIcon fixedWidth icon={faSpinner} spin className="ml-1" /></span>
                    ) : (
                      <span>Send it <FontAwesomeIcon fixedWidth icon={faEnvelopeOpenText} className="ml-1" /></span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
