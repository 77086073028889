import {UserSetting} from "@graphql/api"
import {updateUserSetting} from "@graphql/mutations"
import {CognitoUser} from "amazon-cognito-identity-js"
import {API, graphqlOperation} from "aws-amplify"
import Crypto from "crypto"
import { useRef, useState } from 'react';

const CalLink = ({user, settings}: { user: CognitoUser, settings: UserSetting }) => {
  let linkInput = useRef<HTMLInputElement>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  const copyLink = () => {
    linkInput.current?.select()
  }

  const createLink = async () => {
    if (isLoading) {
      return
    }
    setLoading(true)
    const calCode = Crypto.createHmac('sha256', user?.getUsername()).digest().toString('hex')
    await API.graphql(graphqlOperation(updateUserSetting, {input: {owner: user.getUsername(), calCode}}))
    settings.calCode = calCode
    setLoading(false)
  }

  if (settings?.calCode) {
    return (
      <div>
        <p>Here is your calendar link:</p>
        <input
          ref={linkInput}
          type="text"
          readOnly
          onFocus={(event) => copyLink()}
          value={`https://api.overflightapp.com/calendar/${settings.calCode}`}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
        <p>Detailed instructions coming soon, but if you know how to subscribe to an iCal calendar, this is the link required.</p>
      </div>
    )
  }

  return (
    <div>
      <button
        type="button"
        onClick={() => createLink()}
        disabled={isLoading}
        className="inline-flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {isLoading ? 'Loading' : 'Get calendar link'}
      </button>
    </div>
  )
}

export default CalLink
