import {AmplifyProvider} from '@aws-amplify/ui-react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import App from './App';
import {AppLinks} from './AppLinks';
import './index.css';
import QRCode from './Public/CheckIn/QRCode';
import DocumentLinks from './Public/DocumentLinks';
import Form004 from './Public/Forms/Form004';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
    <AmplifyProvider>
      <BrowserRouter>
        <Routes>
          <Route path="app/*" element={<AppLinks />} />
          <Route path="check-in/:voucher" element={<QRCode />} />
          <Route path="forms/004" element={<Form004 />} />
          <Route path="document/:doc" element={<DocumentLinks />} />
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </AmplifyProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
