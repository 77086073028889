import {useAuthenticator} from '@aws-amplify/ui-react';
import {faBars, faBullhorn, faChevronDown, faCogs, faHardHat, faHelicopter, faHome, faSignOut, faUsers} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Disclosure, Popover, Transition} from '@headlessui/react';
import {Auth} from 'aws-amplify';
import * as React from 'react';
import {Fragment, MouseEvent, useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import tail from '../assets/206-tail.png';
import logo from '../assets/logo-trimmed.png';

const classNames = (...classes: Array<string>) => {
  return classes.filter(Boolean).join(' ')
}

const administration = [
  {
    name: 'Broadcast',
    description: 'Send notifications to app users',
    route: '/admin/broadcasts',
    icon: faBullhorn,
  },
  {
    name: 'Users',
    description: 'User management',
    route: '/admin/users',
    icon: faUsers,
  },
  {
    name: 'Safety Comms',
    description: 'Safety Communications',
    route: '/admin/safety-comms',
    icon: faHardHat,
  }
]

const Header = () => {
  const [isAdmin, setAdmin] = useState<boolean>(false)
  const {authStatus, user} = useAuthenticator();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      if (user) {
        const userGroups = user.signInUserSession.accessToken.payload['cognito:groups']
        setAdmin(userGroups.includes('Admin'))
      }
    })
  }, [])

  useEffect(() => {
    if (authStatus === 'authenticated') {
      // @ts-ignore
      const userGroups = user?.signInUserSession.accessToken.payload['cognito:groups'] ?? []
      setAdmin(userGroups.includes('Admin'))
    }
  }, [authStatus, user]);

  const logout = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    await Auth.signOut();
  }

  return (
    <Disclosure as="nav" className="relative bg-white shadow-md">
      {({open}) => (
        <React.Fragment>
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="/">
                  <span className="sr-only">OverFlight Administration</span>
                  <img className="hidden lg:block w-auto h-12" src={logo} alt="OverFlight Logo"/>
                  <img
                    className="h-8 w-auto sm:h-12 lg:hidden rounded"
                    src={tail}
                    alt=""
                  />
                </a>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Disclosure.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <FontAwesomeIcon fixedWidth className="h-6 w-6" aria-hidden="true" icon={faBars}/>
                </Disclosure.Button>
              </div>
              <nav className="hidden md:flex space-x-10">
                <NavLink to="/" end className={({isActive}) => isActive ? "text-indigo-600 hover:text-indigo-500" : "text-base font-medium text-gray-400 hover:text-gray-900 flex items-start"}>
                  <FontAwesomeIcon fixedWidth icon={faHome} className="flex-shrink-0 h-6 w-6 mr-2" aria-hidden="true"/>
                  Dashboard
                </NavLink>
                <NavLink to="/following" end className={({isActive}) => isActive ? "text-indigo-600 hover:text-indigo-500" : "text-base font-medium text-gray-400 hover:text-gray-900 flex items-start"}>
                  <FontAwesomeIcon fixedWidth icon={faHelicopter} className="flex-shrink-0 h-6 w-6 mr-2" aria-hidden="true"/>
                  Flight Following
                </NavLink>

                {isAdmin && (
                  <Popover className="relative">
                    {({open}) => (
                      <React.Fragment>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-gray-900' : 'text-gray-400',
                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                          )}
                        >
                        <span className="flex items-start">
                        <FontAwesomeIcon fixedWidth icon={faCogs} className="flex-shrink-0 h-6 w-6 mr-2" aria-hidden="true"/>
                        <span>Administration</span>
                        </span>
                          <FontAwesomeIcon
                            fixedWidth
                            className={classNames(
                              open ? 'text-gray-600' : 'text-gray-400',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                            icon={faChevronDown}
                          />
                        </Popover.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
                          >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                {administration.map((item) => (
                                  <Link
                                    key={item.name}
                                    to={item.route}
                                    className={"-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"}
                                  >
                                    <FontAwesomeIcon fixedWidth icon={item.icon} className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true"/>
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">{item.name}</p>
                                      <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </React.Fragment>
                    )}
                  </Popover>)
                }
              </nav>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <a href="/logout" onClick={logout} className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                  Sign Out
                  <FontAwesomeIcon fixedWidth icon={faSignOut} className="ml-2"/>
                </a>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <NavLink to="/" end className={({isActive}) => isActive ? "text-indigo-600 hover:text-indigo-500" : "text-gray-400 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"}>
                Dashboard
              </NavLink>
              <NavLink to="/following" end className={({isActive}) => isActive ? "text-indigo-600 hover:text-indigo-500" : "text-gray-400 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"}>
                Flight Following
              </NavLink>

              <div className="text-gray-900 block px-3 py-2 rounded-md text-base font-medium border-b-2" aria-current={undefined}>Administration</div>
              <div className="pl-3">
                {administration.map(item => (
                  <NavLink key={item.name} to={item.route} end className={({isActive}) => isActive ? "text-indigo-600 hover:text-indigo-500" : "text-gray-400 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"}>
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </React.Fragment>
      )}
    </Disclosure>
  )
}

export default Header
