import {Provider} from "react-redux"
import {Outlet} from "react-router-dom"
import store from '../Store'
import './Admin.css'

const Admin = () => {
  return (
    <Provider store={store}>
      <div className="w-full h-full">
        <Outlet/>
      </div>
    </Provider>
  )
}

export default Admin
