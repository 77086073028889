import {GraphQLResult} from '@aws-amplify/api-graphql';
import {ClerkProvider} from '@clerk/clerk-react';
import {faFileSearch, faSave} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CreateSafetyCommInput, CreateSafetyCommMutation, SafetyCommString} from '@graphql/api';
import {createSafetyComm} from '@graphql/mutations';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {DateTime} from 'luxon';
import {useState} from 'react';
import {Store} from 'react-notifications-component';
import DropboxAccess from './_DropboxAccess';
import {Dropbox} from './types';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const Create = () => {
  const [file, setFile] = useState<Dropbox.File | null>(null);
  const [dropboxToken, setDropboxToken] = useState<string | null>(null);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();

  const save = async () => {
    if (!file) {
      return;
    }

    if (isSaving) {
      return;
    }
    setSaving(true);

    let sharingUrl;
    let shareLinkResult;

    // using `https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings`, first we
    // create a shared link with access: viewer and audience: public
    // then, once that completes, we create the SafetyComm using API.graphql
    shareLinkResult = await fetch('https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${dropboxToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        path: file.metadata.metadata.path_lower,
        settings: {
          requested_visibility: 'public',
          audience: 'public',
          access: 'viewer',
          allow_download: true,
        }
      }),
    });

    if (shareLinkResult.ok) {
      const json = await shareLinkResult.json();
      sharingUrl = json.url;
    } else if (shareLinkResult.status === 409) {
      shareLinkResult = await fetch('https://api.dropboxapi.com/2/sharing/list_shared_links', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${dropboxToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          path: file.metadata.metadata.id,
        }),
      });

      const json = await shareLinkResult.json();
      sharingUrl = json.links[0].url;
    }

    console.debug('sharingUrl', sharingUrl);

    try {
      if (sharingUrl) {
        const user = await Auth.currentUserInfo();
        // @ts-ignore
        const attributes = user.attributes;
        const publishedBy = attributes.name;

        const {data} = await API.graphql(graphqlOperation(createSafetyComm, {
          input: {
            title,
            modelType: SafetyCommString.safetycomm,
            // description,
            url: sharingUrl,
            publishedBy,
            publishedAt: DateTime.utc().toISO(),
          } as CreateSafetyCommInput
        })) as GraphQLResult<CreateSafetyCommMutation>;

        if (data?.createSafetyComm?.id) {
          setFile(null);
          setTitle('');

          Store.addNotification({
            message: 'Safety Comms saved',
            title: 'Success',
            type: 'success',
            container: 'bottom-center',
            dismiss: {
              duration: 5000
            }
          });
        }
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Create Safety Com</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Create a new safety com.</p>
            </div>
            <div>
              <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      autoComplete="title"
                      className="input block w-full"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="col-span-6 hidden">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      className="input block w-full"
                      placeholder="Description"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  {/* @ts-ignore */}
                  <ClerkProvider publishableKey={clerkPubKey}>
                    <DropboxAccess setDropboxToken={setDropboxToken} dropboxToken={dropboxToken} selectFile={setFile} selectedFile={file} />
                  </ClerkProvider>

                  {file && <div className="col-span-6 flex flex-col gap-y-2">
                    <p className="font-bold text-red-500">
                      Continuing with this file will automatically change the sharing settings to "Anyone with the link can view".
                    </p>
                    <dl>
                      <dt>Filename</dt>
                      <dd><code>{file.metadata.metadata.path_display}</code></dd>
                    </dl>

                    <button
                      onClick={() => setFile(null)}
                      className="flex-0 uppercase text-xs text-blue-400 rounded border-blue-400 border p-2 w-56"
                      style={{height: 'fit-content'}}
                    >
                      <FontAwesomeIcon fixedWidth icon={faFileSearch} className="mr-2" />
                      Choose another file
                    </button>
                  </div>}

                  <div className="col-span-6 flex flex-row gap-x-2 hidden">
                    <input
                      id="broadcast"
                      name="broadcast"
                      type="checkbox"
                      value="true"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      defaultChecked
                    />
                    <label htmlFor="broadcast" className="block text-sm font-medium text-gray-700">
                      Broadcast now?
                    </label>
                  </div>

                  <div className="col-span-6">
                    <button
                      onClick={() => save()}
                      className="flex-0 uppercase text-xs text-blue-400 rounded border-blue-400 border p-2 w-56"
                      style={{height: 'fit-content'}}
                      disabled={isSaving}
                    >
                      <FontAwesomeIcon fixedWidth icon={faSave} className="mr-2" />
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
