import {faExclamation, faMobile, faSync, IconDefinition} from "@fortawesome/pro-duotone-svg-icons"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PushSubscription, UserSetting} from '@graphql/api'
import {Dialog, Transition} from "@headlessui/react"
import {API} from "aws-amplify";
import {UserType} from "aws-sdk/clients/cognitoidentityserviceprovider"
import { Fragment, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import {Store} from "react-notifications-component"
import tail from "../../assets/206-tail.png"
import TooltipButton from "../../Components/TooltipButton"
import {GroupIcons} from "./UserList"

export type UserGroup = 'Admin' | 'Ground' | 'Maintenance' | 'TeamLead' | 'Pilot'
type Props = {
  user: UserType
  settings?: UserSetting
  tokens?: Array<PushSubscription>
  groups?: Set<UserGroup>
}

const User = ({user, settings, tokens = [], groups}: Props) => {
  const attributes: Record<string, any> = user.Attributes?.reduce((obj, item) => Object.assign(obj, {[item.Name]: item.Value}), {}) || {}
  const name = attributes?.name || user.Username

  const [isUpdating, setUpdating] = useState<boolean>(false)
  const [showingConfirmation, showConfirmation] = useState<null | 'password' | 'update'>(null);

  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  const forceUpdate = async (force: boolean = false) => {
    if (!force) {
      showConfirmation('update');
      return
    }
    if (isUpdating) {
      return
    }

    showConfirmation(null);
    setUpdating(true);

    try {
      await API.post('pilot001Rest', '/tasks/refresh', {
        body: {
          user: {
            userName: user.Username,
          },
        }
      })
    } finally {
      Store.addNotification({
        message: `Tasks sync for ${name}`,
        title: 'Success',
        type: 'success',
        container: 'bottom-center',
        dismiss: {
          duration: 5000
        }
      })

      setUpdating(false)
    }
  }

  const resetPassword = async (force: boolean = false) => {
    if (!force) {
      showConfirmation('password');
      return
    }
    if (isUpdating) {
      return
    }

    showConfirmation(null);
    setUpdating(true)

    try {
      const body = {email: user.Username as string}
      console.debug('Resetting password for user', {user, body})

      const resp = await API.post('pilot001Rest', '/user/reset', {
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      console.debug(resp)
    } catch (err) {
      console.error('resetUser error', err)
    } finally {
      setUpdating(false)
    }
  }

  const modalText = {
    password: {
      title: 'Send this user a password-reset email?',
      button: 'Reset',
      action: resetPassword,
    },
    update: {
      title: "Force this user's tasks to sync?",
      button: 'Update',
      action: forceUpdate,
    }
  };

  const devices = useMemo(() => {
    return new Set(tokens.map((token: PushSubscription) => token.deviceType).filter(type => type))
  }, [tokens])

  const groupIcons = useMemo(() => {
    let icons: Array<JSX.Element | undefined> = []

    if (groups) {
      icons = Array.from(groups).map(group =>
        <TooltipButton key={group} disabled tooltip={group} className="py-2 px-1">
          <FontAwesomeIcon fixedWidth icon={GroupIcons.get(group) as IconDefinition} />
        </TooltipButton>
      )
    }

    return icons
  }, [groups])

  return (
    <React.Fragment>
      <tr>
        <td className="px-6 py-4 whitespace-no-wrap">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img className="h-10 w-10 rounded-full bg-blue-900" src={attributes.picture || tail} alt="" />
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {name}
              </div>
              <div className="text-sm text-gray-500">
                {settings?.callsign}
              </div>
              <div className="text-sm text-gray-500">
                {attributes.email}
              </div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <code>{settings?.appVersion || 'unknown'}</code><br />
          <FontAwesomeIcon fixedWidth icon={faMobile} className="mr-1" />{devices.size ? (<code>{Array.from(devices).join(', ')}</code>) : 'unknown'}
        </td>
        {/*<td className="px-6 py-4 whitespace-nowrap">*/}
        {/*  <UserStatus enabled={user.Enabled as boolean} status={user.UserStatus as string} />*/}
        {/*</td>*/}
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex-row">
          {groupIcons}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex-row">
          {/*<TooltipButton disabled={isUpdating} className="p-2" onClick={() => resetPassword()} tooltip={isUpdating ? 'Resetting' : 'Reset password'}><FontAwesomeIcon fixedWidth spin={isUpdating} icon={faKey}/></TooltipButton>*/}
          <TooltipButton disabled={isUpdating} className="p-2" onClick={() => forceUpdate()} tooltip={isUpdating ? 'Syncing' : 'Sync tasks'}><FontAwesomeIcon fixedWidth spin={isUpdating} icon={faSync} /></TooltipButton>
        </td>
      </tr>
      <Transition.Root show={showingConfirmation !== null} as={Fragment}>
        <Dialog
          open={showingConfirmation !== null}
          onClose={() => showConfirmation(null)}
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FontAwesomeIcon icon={faExclamation} className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Are you sure?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {showingConfirmation !== null && modalText[showingConfirmation].title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      showingConfirmation !== null && modalText[showingConfirmation].action(true)
                    }}
                  >
                    {showingConfirmation !== null ? modalText[showingConfirmation].button : 'Loading...'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => showConfirmation(null)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  )
}

export default User
